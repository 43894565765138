export const isDark = function( color ) {
	if ( !color ) {
		return true;
	}

	// rgb(0,0,0)
	// rgba(0,0,0,0)
	let rgbColor = color.match( /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(?:\d+(?:\.\d+)?))?\)$/ );
	if ( rgbColor && 4 === rgbColor.length ) {
		rgbColor = [
			rgbColor[0],
			parseInt( rgbColor[1], 10 ),
			parseInt( rgbColor[2], 10 ),
			parseInt( rgbColor[3], 10 ),
		];
	}

	if ( !rgbColor || 4 !== rgbColor.length ) {
		// #000000
		// #00000000
		rgbColor = color.match( /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})(?:[0-9a-f]{2})?$/i );
		if ( rgbColor && 4 === rgbColor.length ) {
			rgbColor = [
				rgbColor[0],
				parseInt( rgbColor[1], 16 ),
				parseInt( rgbColor[2], 16 ),
				parseInt( rgbColor[3], 16 ),
			];
		}
	}

	if ( !rgbColor || 4 !== rgbColor.length ) {
		// #000
		// #0000
		rgbColor = color.match( /^#?([0-9a-f])([0-9a-f])([0-9a-f])(?:[0-9a-f])?$/i );
		if ( rgbColor && 4 === rgbColor.length ) {
			rgbColor = [
				rgbColor[0],
				parseInt( rgbColor[1], 16 ) * 17,
				parseInt( rgbColor[2], 16 ) * 17,
				parseInt( rgbColor[3], 16 ) * 17,
			];
		}
	}

	if ( !rgbColor || 4 !== rgbColor.length ) {
		return true;
	}

	const r = rgbColor[1];
	const g = rgbColor[2];
	const b = rgbColor[3];

	// HSP equation from http://alienryderflex.com/hsp.html
	const hsp = Math.sqrt(
		0.299 * ( r * r ) +
		0.587 * ( g * g ) +
		0.114 * ( b * b )
	);

	if ( 127.5 >= hsp ) {
		return true;
	}

	return false;
};
