import { smoothScrollTo } from '../helpers/smooth-scroll';

( function() {
	function scrollDown( e ) {
		if ( !this.href ) {
			return;
		}

		const urlObject = new URL( this.href );
		const anchor = document.querySelector( urlObject.hash );

		if ( !anchor ) {
			return;
		}

		e.preventDefault();

		// source: https://stackoverflow.com/a/28633515
		const bodyScrollTop =			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0;
		const scrollTo = bodyScrollTop + anchor.getBoundingClientRect().top;
		smoothScrollTo( 0, scrollTo, 600 );
	}

	let initCustomScroll = () => {
		if ( 'complete' !== document.readyState ) {
			return;
		}

		initCustomScroll = () => {}; // noop

		const scrollActions = document.querySelectorAll( '.js-scroll-action' );

		if ( 1 > scrollActions.length ) {
			return;
		}

		for ( let i = 0; i < scrollActions.length; i += 1 ) {
			scrollActions[i].addEventListener( 'click', scrollDown );
		}
	};

	let initNativeScroll = () => {
		if ( 'complete' !== document.readyState ) {
			return;
		}

		initNativeScroll = () => {}; // noop

		document.documentElement.style['scroll-behavior'] = 'smooth';
	};

	function hasNativeSmoothScroll() {
		if ( window.CSS && window.CSS.supports && window.CSS.supports( 'scroll-behavior', 'smooth' ) ) {
			return true;
		}

		return false;
	}

	if ( hasNativeSmoothScroll() ) {
		// only initiate if browser DOES support native smooth scrolling
		document.addEventListener( 'readystatechange', () => {
			initNativeScroll();
		}, false );

		initNativeScroll();
	} else {
		// only initiate if browser DOES NOT support native smooth scrolling
		document.addEventListener( 'readystatechange', () => {
			initCustomScroll();
		}, false );

		initCustomScroll();
	}
}() );
