import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

defineCustomElement( 'mr-video-player', {
	attributes: [
		{
			attribute: 'video-id',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		resolve() {
			const resolved = new Promise( ( resolve ) => {
				this.gatherElements( 10, () => {
					resolve();
				} );
			} );

			return resolved;
		}

		gatherElements( after = 10, done = () => {} ) {
			const video = this.el.querySelector( '.js-video-player-video' );

			if ( video ) {
				this.elements = {};
				this.elements.video = video;

				this.setVideoSourceDynamically( video );

				done();

				return;
			}

			setTimeout( () => {
				this.gatherElements( after * 2, done );
			}, after );
		}

		init() {
			this.fullscreen = false;
			this.initialSetup = true;
			this.initialVideoStateSet = false;
			this.mobile = false;
		}

		setInitialVideoState() {
			if ( this.initialVideoStateSet ) {
				return;
			}

			this.initialVideoStateSet = true;

			this.play();
			this.unmute();
		}

		setVideoSourceDynamically( videoEl ) {
			if ( !videoEl.hasAttribute( 'multiple-sources' ) ) {
				return;
			}

			const multipleSourcesData = this.validJSONForVideoSources( videoEl.getAttribute( 'multiple-sources' ) );
			if ( !multipleSourcesData ) {
				return;
			}

			const results = this.videoConnection( this.videoScreenSize( multipleSourcesData ) );
			if ( !results || !results.source ) {
				return;
			}

			this.elements.video.src = results.source;
		}

		validJSONForVideoSources( isValid ) {
			try {
				const parsed = JSON.parse( isValid );
				if ( Array.isArray( parsed ) && 0 < parsed.length ) {
					return parsed;
				}

			} catch ( _ ) { }

			return false;
		}

		videoScreenSize( options ) {
			options.sort( ( a, b ) => {
				return a.width - b.width;
			} );

			const screenWidth = window.innerWidth;

			let indexOfFirstBiggerItem = options.findIndex( ( option ) => {
				return option.width >= screenWidth;
			} );

			if ( -1 === indexOfFirstBiggerItem ) {
				indexOfFirstBiggerItem = options.length - 1;
			}

			let indexOfFirstBiggerItemRetina = options.findIndex( ( option ) => {
				return option.width >= ( screenWidth * Math.max( 1, Math.min( 2, window.devicePixelRatio ) ) );
			} );

			if ( -1 === indexOfFirstBiggerItemRetina ) {
				indexOfFirstBiggerItemRetina = options.length - 1;
			}

			if ( indexOfFirstBiggerItem !== indexOfFirstBiggerItemRetina ) {
				return [
					...options.slice( 0, indexOfFirstBiggerItem + 1 ),
					options[indexOfFirstBiggerItemRetina],
				];
			}

			return options.slice( 0, indexOfFirstBiggerItem + 1 );
		}

		videoConnection( options ) {
			try {
				const deviceOn4G = '4g' === navigator.connection.effectiveType;
				const saveDataIsOn = true === navigator.connection.saveData;

				let sourceIndex = options.length - 1;

				if ( saveDataIsOn ) {
					sourceIndex = sourceIndex - 1;
				}

				if ( !deviceOn4G ) {
					sourceIndex = sourceIndex - 1;
				}

				return options[sourceIndex] ?? options[0];

			} catch ( _ ) {
				/* noop */
			}

			return options[options.length - 1];
		}

		bind() {
			// mr-video-teaser events
			this.on( 'mr-video-teaser:open', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				if ( this.initialSetup ) {
					this.setup( true );
				} else if ( !this.initialSetup && this.elements.video.paused ) {
					this.play();
				}

				this.mobile = e.detail.fullscreen || false;

				this.open();
			}, window );

			// mr-song-pagination events
			this.on( 'mr-song-pagination:close', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.close();
			}, window );

			// mr-video-controls events
			this.on( 'mr-video-controls:togglePlayback', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.togglePlayback();
			}, window );

			this.on( 'mr-video-controls:toggleMute', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.toggleMute();
			}, window );

			this.on( 'mr-video-controls:toggleFullscreen', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.toggleFullscreen();
			}, window );

			this.on( 'mr-video-controls:jump', ( e ) => {
				if ( !this.videoId ||
					this.videoId !== e.detail.videoId ||
					!this.elements.video ||
					!this.duration ||
					!e.detail.position ) {
					return;
				}

				const currentTime = this.duration * e.detail.position;
				if ( Number.isNaN( currentTime ) ) {
					return;
				}

				this.elements.video.currentTime = currentTime;
			}, window );


			// video events
			this.on( 'canplaythrough', () => {
				if ( this.initialSetup ) {
					this.setup();
				}
			}, this.elements.video );

			this.on( 'play', () => {
				this.el.classList.add( 'is-playing' );
				this.dispatch( 'playing' );
			}, this.elements.video );

			this.on( 'pause', () => {
				this.el.classList.remove( 'is-playing' );
				this.dispatch( 'paused' );
			}, this.elements.video );

			this.on( 'volumechange', () => {
				if ( this.elements.video.muted ) {
					this.dispatch( 'muted' );
				} else {
					this.dispatch( 'unmuted' );
				}
			}, this.elements.video );

			this.on( 'timeupdate', () => {
				if ( this.duration && this.elements.video.currentTime ) {
					const currentTimePercentage = this.elements.video.currentTime / this.duration;

					this.el.dispatchEvent( new CustomEvent( 'mr-video-player:timeUpdate', {
						bubbles: true,
						cancelable: true,
						detail: {
							currentTimePercentage: currentTimePercentage,
							videoId: this.videoId,
						},
					} ) );
				}
			}, this.elements.video );

			// user events
			let moveThrottle = false;

			this.on( 'click .js-video-player-close', () => {
				this.close();
			} );

			this.on( 'keydown', ( e ) => {
				// esc key
				if ( 27 === e.keyCode ) {
					this.close();
				}
			}, window );

			this.on( 'mouseleave', () => {
				moveThrottle = true;
				this.timeInactivity( 960 );
			}, this.el );

			this.on( 'mouseenter', () => {
				moveThrottle = false;
				this.setFocus();
			}, this.el );

			this.on( 'click', () => {
				this.setFocus();
			}, this.el );

			this.on( 'mousemove', ( e, target ) => {
				if ( !moveThrottle ) {
					if ( this.el.contains( target ) ) {
						moveThrottle = true;
						this.setFocus();
					}

					setTimeout( () => {
						moveThrottle = false;
						this.setFocus();
					}, 240 );
				}
			}, this.el );

			// window events
			this.on( 'webkitfullscreenchange', () => {
				this.toggleOSFullscreen();
			}, window );

			this.on( 'mozfullscreenchange', () => {
				this.toggleOSFullscreen();
			}, window );

			this.on( 'fullscreenchange', () => {
				this.toggleOSFullscreen();
			}, window );

			// older spec for iPads
			this.on( 'webkitendfullscreen', () => {
				this.closeOSFullscreen();
			}, this.elements.video );
		}

		setup( playThrough = false ) {
			this.duration = this.elements.video.duration;

			if ( isNaN( this.duration ) || 0 === this.duration ) {
				this.tryForDuration( 5 );
			}

			this.initialSetup = false;
			this.timeInactivity( 3008 );

			if ( playThrough ) {
				this.setInitialVideoState();
			}
		}

		tryForDuration( max = 5 ) {
			let tries = 0;

			this.checkInterval = setInterval( () => {
				tries += 1;

				if ( !isNaN( this.elements.video.duration ) && 0 < this.elements.video.duration ) {
					this.duration = this.elements.video.duration;
					clearInterval( this.checkInterval );
				}

				if ( tries === max ) {
					clearInterval( this.checkInterval );
				}
			}, 960 );
		}

		setFocus() {
			if ( this.initialSetup ) {
				return;
			}

			clearTimeout( this.unfocusTimer );
			this.showControls();
			this.timeInactivity( 3008 );
		}

		timeInactivity( time ) {
			clearTimeout( this.unfocusTimer );

			this.unfocusTimer = setTimeout( () => {
				this.hideControls();
			}, time );
		}

		togglePlayback() {
			if ( this.elements.video.paused ) {
				this.play();
			} else {
				this.elements.video.pause();
			}
		}

		mute() {
			if ( !this.elements.video.muted ) {
				this.elements.video.muted = true;
				this.dispatch( 'muted' );
			}
		}

		unmute() {
			if ( this.elements.video.muted ) {
				this.elements.video.muted = false;
				this.dispatch( 'unmuted' );
			}
		}

		play() {
			if ( this.elements.video.paused ) {

				const playPromise = this.elements.video.play();
				if ( !playPromise ) {
					return;
				}

				playPromise.then( () => {
					// all is well
				} ).catch( ( err ) => {
					this.dispatch( 'paused' );

					console.log( 'video-player : start playback for video in viewport - failed' );

					if ( this.elements.video.src ) {
						console.log( 'video-player : src - ' + this.elements.video.src );
					}

					if ( this.elements.video.error ) {
						console.log( 'video-player : err - ' + this.elements.video.error.code + '; details :', this.elements.video.error.message );
					} else {
						// Devices with battery or data saving mode enabled might not allow playing this video
						console.log( 'video-player : unable / not allowed to play video' );
					}

					if ( isUnexpectedError( err ) ) {
						bugsnagClient.notify( err );
					}
				} );
			}
		}

		pause() {
			if ( !this.elements.video.paused ) {
				this.elements.video.pause();
			}
		}

		toggleMute() {
			if ( this.elements.video.muted ) {
				this.elements.video.muted = false;
			} else {
				this.elements.video.muted = true;
			}
		}

		toggleFullscreen() {
			if ( this.elements.video.requestFullscreen ) {
				this.elements.video.requestFullscreen();
			} else if ( this.elements.video.msRequestFullscreen ) {
				this.elements.video.msRequestFullscreen();
			} else if ( this.elements.video.mozRequestFullScreen ) {
				this.elements.video.mozRequestFullScreen();
			} else if ( this.elements.video.webkitRequestFullscreen ) {
				this.elements.video.webkitRequestFullscreen();
			} else if ( this.elements.video.webkitEnterFullscreen ) {
				this.elements.video.webkitEnterFullscreen();
			} else {
				console.warn( '[mr-video] element doesn\'t support fullscreen' );
			}
		}

		toggleOSFullscreen() {
			this.fullscreen = !this.fullscreen;

			if ( this.fullscreen || !this.mobile ) {
				return;
			}

			this.closeOSFullscreen();
		}

		closeOSFullscreen() {
			this.close();
			this.fullscreen = false;
		}

		open() {
			this.el.classList.add( 'is-open' );
			document.querySelector( 'body' ).classList.add( 'has-open-video-overlay' );

			this.setup();

			const slidesIndex = document.getElementById( 'slides-index' );

			if ( slidesIndex ) {
				slidesIndex.disabled = true;
			}

			if ( !this.mobile ) {
				return;
			}

			this.toggleFullscreen();
		}

		close() {
			this.pause();

			this.el.classList.remove( 'is-open' );
			document.querySelector( 'body' ).classList.remove( 'has-open-video-overlay' );

			const slidesIndex = document.getElementById( 'slides-index' );

			if ( slidesIndex ) {
				slidesIndex.disabled = false;
			}
		}

		dispatch( event ) {
			if ( this.videoId ) {
				this.el.dispatchEvent( new CustomEvent( `mr-video-player:${event}`, {
					bubbles: true,
					cancelable: true,
					detail: {
						videoId: this.videoId,
					},
				} ) );
			}
		}

		showControls() {
			this.el.setAttribute( 'data-show-controls', '' );
		}

		hideControls() {
			this.el.removeAttribute( 'data-show-controls' );
		}
	},
} );

const isUnexpectedError = ( err ) => {
	if ( err && 'AbortError' === err.name ) {
		return false;
	}

	if ( err && 'PlayInterrupted' === err.name ) {
		return false;
	}

	if ( err && 'NotAllowedError' === err.name ) {
		return false;
	}

	if ( err && 'NotSupportedError' === err.name ) {
		return false;
	}

	return true;
};
