export function renderAudioLevelsOnCanvas( canvas: HTMLCanvasElement, data: Array<number>, fill = '#ffffff' ): void {
	if ( !data || 0 === data.length ) {
		return;
	}

	let audioLevelsWindowInnerWidth;

	if ( !audioLevelsWindowInnerWidth ) {
		audioLevelsWindowInnerWidth = window.innerWidth;
	}

	const canvasCtx = canvas.getContext( '2d' );
	if ( !canvasCtx ) {
		return;
	}

	const height = 30;

	let width = audioLevelsWindowInnerWidth - 60;

	if ( matchMedia( '(min-width: 768px) and (max-width: 1919px)' ).matches ) {
		width = audioLevelsWindowInnerWidth - 286;
	} else if ( matchMedia( '(min-width: 1920px)' ).matches ) {
		width = audioLevelsWindowInnerWidth - 346;
	}

	canvas.width = width * window.devicePixelRatio;
	canvas.height = height * window.devicePixelRatio;

	if ( 0 >= width ) {
		return;
	}

	canvasCtx.fillStyle = fill;

	canvasCtx.clearRect( 0, 0, width, height );
	canvasCtx.scale( window.devicePixelRatio, window.devicePixelRatio );

	const slices = Math.round( width / 2 );
	const sliceSize = data.length / ( width / 2 );

	for ( let i = 0; i < slices; i++ ) {
		const currentSlice = data.slice( i * sliceSize, ( i + 1 ) * sliceSize );
		const v = Math.max( ...currentSlice ) / 100;
		canvasCtx.fillRect( i * 2, height - ( v * ( height ) ), 1, v * ( height ) );
	}
}
