export class MrSlidesController extends HTMLElement {
	static get observedAttributes() {
		return [
			'value',
			'hidden',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.data = null;
	}

	// Life cycle
	connectedCallback() {
		this.trySetData();
	}

	disconnectedCallback() {
		this.data = null;
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'value' === attrName ) {
			this.trySetData();

			return;
		}

		if ( 'hidden' === attrName ) {
			if ( null === newVal ) {
				this.show();
			} else {
				this.hide();
			}

			return;
		}
	}

	trySetData() {
		try {
			const data = JSON.parse( this.getAttribute( 'value' ) );
			this.data = data;
		} catch ( err ) {
			console.warn( err );
		}

		this.prepareSlides();
	}

	prepareSlides() {}

	hide() {}

	show() {}

	dataForSlideAtIndex( index ) {
		if ( !this.data || !this.data.length || index >= this.data.length ) {
			return;
		}

		return this.data[index];
	}
}
