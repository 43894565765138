class MrGoToIndex extends HTMLElement {
	static get observedAttributes() {
		return [
			'disabled',
			'for',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		// Event Handlers
		this.click = ( e ) => {
			if ( !e.target || !e.target.closest ) {
				return;
			}

			// not all but some elements that can be displayed also have a discrete page.
			// if a user clicks on such an element with a modifier key, we preserver the browser default behavior
			const closestA = e.target.closest( 'a' );
			if ( closestA && closestA.getAttribute( 'href' ) ) {
				if ( e.metaKey || e.shiftKey || e.ctrlKey || e.altKey ) {
					return;
				}
			}

			if ( this.disabled ) {
				return;
			}

			if ( 'undefined' === typeof e.currentTarget.dataset.for ) {
				return;
			}

			e.preventDefault();
			this.goTo( e.currentTarget.dataset.for );
		};
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'disabled' === attrName ) {
			if ( null === newVal ) {
				this._addEventListeners();
			} else {
				this._removeEventListeners();
			}

			return;
		}
	}

	_addEventListeners() {
		this.galleryLinks = document.getElementsByClassName( 'js-spotlight-gallery-link' );

		for ( let i = 0; i < this.galleryLinks.length; i++ ) {
			this.galleryLinks[i].addEventListener( 'click', this.click );
		}
	}

	_removeEventListeners() {
		for ( let i = 0; i < this.galleryLinks.length; i++ ) {
			this.galleryLinks[i].removeEventListener( 'click', this.click );
		}
	}

	goTo( index ) {
		const forEl = this.forEl;
		if ( forEl ) {
			forEl.setAttribute( 'value', index );
		}
	}
}

customElements.define( 'mr-go-to-index', MrGoToIndex );
