/*
 * Mr Creative List 🗂
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

import { isDark } from '../helpers/is-dark';

defineCustomElement( 'mr-creative-list', {
	controller: class extends BaseController {
		resolve() {
			const resolved = new Promise( ( resolve ) => {
				this.gatherElements( 50, () => {
					resolve();
				} );
			} );

			return resolved;
		}

		gatherElements( after = 50, done = () => {} ) {
			const creativeItems = Array.from( this.el.getElementsByClassName( 'js-creative-list__item' ) );
			if ( creativeItems && creativeItems.length ) {
				this.elements = {};
				this.elements.items = creativeItems;
				done();

				return;
			}

			setTimeout( () => {
				this.gatherElements( after * 2, done );
			}, after );
		}

		init() {
			this.originalBackgroundColor = document.body.style.backgroundColor;
			this.originalColor = document.body.style.color;

			const resetCurrentHandler = () => {
				this.elements.items.forEach( ( item ) => {
					item.classList.remove( 'is-current' );
				} );

				// resets the reel icon to the correct color.
				this.setIconColor( this.originalColor );
			};

			window.addEventListener( 'mr-pagereset', resetCurrentHandler, false );
		}

		bind() {
			if ( 0 < this.elements.items.length ) {
				this.elements.items.forEach( ( item ) => {
					this.on( 'mouseenter', () => {
						this.setColors( item );

						this.once( 'mr-window-watcher:scroll', () => {
							this.resetColors();
						}, document.body );
					}, item );

					this.on( 'mouseleave', () => {
						this.resetColors();
					}, item );

					this.once( 'mr-page-transition', () => {
						this.off( 'mouseenter', item );
						this.off( 'mouseleave', item );

						this.off( 'mr-window-watcher:scroll', document.body );
					}, window );
				} );

				// Needed for safari cache to rebind
				this.once( 'pageshow', () => {
					console.log( 'rebind after "pageshow" for back/forwards cache' );
					this.bind();
				}, window );
			}
		}

		setColors( item ) {
			if ( !this.isAnimatingCreativeList() ) {
				const backgroundColor = item.getAttribute( 'data-transition-background-color' );
				if ( backgroundColor ) {
					document.body.style.backgroundColor = `${backgroundColor}`;
				} else {
					document.body.style.backgroundColor = this.originalBackgroundColor;
				}

				const color = item.getAttribute( 'data-transition-color' );
				if ( color ) {
					document.body.style.color = `${color}`;
				} else {
					document.body.style.color = this.originalColor;
				}

				this.setIconColor( color );
			}
		}

		resetColors() {
			if ( !this.isAnimatingCreativeList() ) {
				document.body.style.backgroundColor = this.originalBackgroundColor;
				document.body.style.color = this.originalColor;

				this.setIconColor( this.originalColor );
			}
		}

		isAnimatingCreativeList() {
			return document.body.classList.contains( 'is-animating-creative-list' );
		}

		setIconColor( color ) {
			if ( isDark( color ) ) {
				this.el.classList.remove( 'creative-list--icon-white' );
				this.el.classList.add( 'creative-list--icon-black' );
			} else {
				this.el.classList.add( 'creative-list--icon-white' );
				this.el.classList.remove( 'creative-list--icon-black' );
			}
		}
	},
} );
