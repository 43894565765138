/*
 * Mr Video Controls 🎮
 *
 * Sends out and listens to html5 media events of media custom elements (Mr Video)
 *
 * It supports:
 *
 * [1] play / pause          (togglePlayback)
 * [2] mute / unmute         (toggleMute)
 * [3] fullscreen request    (toggleFullscreen)
 * [4] jump to time position (jump)
 * [5] scrubbing             (also jump, but with sauce)
 * [6] jumping to waypoints
 * [7] Auto show / hide controls
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video-controls', {
	attributes: [
		{
			attribute: 'video-id',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		resolve() {
			const resolved = new Promise( ( resolve ) => {
				this.gatherElements( 10, () => {
					resolve();
				} );
			} );

			return resolved;
		}

		gatherElements( after = 10, done = () => {} ) {
			const fullscreen = this.el.querySelector( '.js-video-controls-fullscreen' );
			const marker = this.el.querySelector( '.js-video-controls-marker' );
			const mute = this.el.querySelector( '.js-video-controls-mute' );
			const play = this.el.querySelector( '.js-video-controls-play' );
			const played = this.el.querySelector( '.js-video-controls-played' );
			const range = this.el.querySelector( '.js-video-controls-range' );
			const audioProgress = this.el.querySelector( '.js-video-controls-audio-progress' );
			const waypoints = Array.from( this.el.querySelectorAll( '.js-video-controls-waypoint' ) );

			this.elements = {};

			if ( audioProgress ) {
				this.elements.audioProgress = audioProgress;
			}

			if (
				fullscreen &&
				marker &&
				mute &&
				play &&
				played &&
				range &&
				waypoints
			) {
				this.elements.fullscreen = fullscreen;
				this.elements.marker = marker;
				this.elements.mute = mute;
				this.elements.play = play;
				this.elements.played = played;
				this.elements.range = range;
				this.elements.waypoints = waypoints;
				done();

				return;
			}

			setTimeout( () => {
				this.gatherElements( after * 2, done );
			}, after );
		}

		init() {
			this.continuePlaying = false;
			this.playing = false;
			this.rangeFocus = false;
		}

		bind() {
			// [1]
			if ( this.elements.play ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'togglePlayback' );
				}, this.elements.play );
			}

			// [2]
			if ( this.elements.mute ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'toggleMute' );
				}, this.elements.mute );
			}

			// [3]
			if ( this.elements.fullscreen ) {
				this.on( 'click', () => {
					if ( !this.videoId ) {
						this.unbind();

						return;
					}

					this.dispatch( 'toggleFullscreen' );
				}, this.elements.fullscreen );
			}

			if ( this.elements.range ) {
				// [4]
				this.on( 'click', ( e ) => {
					if ( !this.videoId || !this.elements.range ) {
						this.unbind();

						return;
					}

					this.jump( e.clientX );
				}, this.elements.range );

				// [5]
				this.on( 'mousedown', ( e ) => {
					if ( !this.elements.range ) {
						return;
					}

					e.preventDefault();
					e.stopPropagation();

					if ( this.playing ) {
						this.continuePlaying = true;
						this.dispatch( 'togglePlayback' );
					} else {
						this.continuePlaying = false;
					}

					this.rangeFocus = true;
				}, this.elements.range );
			}

			// [5]
			this.on( 'mouseup', ( e ) => {
				if ( !this.elements.range || !this.rangeFocus ) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				if ( this.continuePlaying ) {
					this.dispatch( 'togglePlayback' );
				}

				this.jump( e.clientX );

				this.rangeFocus = false;
			}, window );

			// [5]
			this.on( 'mousemove', ( e ) => {
				if ( !this.elements.played || !this.elements.range || !this.rangeFocus ) {
					return;
				}

				const mouseX = e.clientX;
				const length = this.elements.range.offsetWidth;
				const offset = this.elements.range.getBoundingClientRect().left;
				const position = ( mouseX - offset ) / length;

				let scrubLocation = 0;

				if ( mouseX < offset ) {
					scrubLocation = 0;
				} else if ( mouseX >= offset && mouseX <= ( length + offset ) ) {
					scrubLocation = mouseX - offset;
				} else if ( mouseX > ( length + offset ) ) {
					scrubLocation = length;
				}

				const scrubRange = () => {
					this.elements.marker.style.transform = `translateX(${scrubLocation}px)`;
					this.elements.played.style.transform = `scaleX(${scrubLocation / length}px)`;
				};

				if (
					position &&
					!isNaN( position ) &&
					0 < position &&
					scrubLocation &&
					!isNaN( scrubLocation )
				) {
					window.requestAnimationFrame( scrubRange );

					this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
						bubbles: true,
						cancelable: true,
						detail: {
							position: position,
							videoId: this.videoId,
						},
					} ) );
				}
			}, window );

			// [6]
			if ( this.elements.waypoints.length ) {
				this.elements.waypoints.forEach( ( waypoint ) => {
					this.on( 'click', ( e ) => {
						if ( !this.videoId ) {
							this.unbind();

							return;
						}

						e.preventDefault();

						const position = waypoint.dataset.position;

						if ( null !== position ) {
							this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
								bubbles: true,
								cancelable: true,
								detail: {
									position: position / 100,
									videoId: this.videoId,
								},
							} ) );
						}
					}, waypoint );
				} );
			}

			// mr-video events

			// [7]
			this.on( 'mr-video-player:playing', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.play ) {
					return;
				}

				this.playing = true;
				this.elements.play.classList.add( 'is-toggled' );
			}, window );

			this.on( 'mr-video-player:paused', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.play ) {
					return;
				}

				this.playing = false;
				this.elements.play.classList.remove( 'is-toggled' );
			}, window );

			this.on( 'mr-video-player:unmuted', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.mute ) {
					return;
				}


				this.elements.mute.classList.remove( 'is-toggled' );
			}, window );

			this.on( 'mr-video-player:muted', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.mute ) {
					return;
				}

				this.elements.mute.classList.add( 'is-toggled' );
			}, window );

			this.on( 'mr-video-player:timeUpdate', ( e ) => {
				if (
					!this.videoId ||
					this.videoId !== e.detail.videoId ||
					!this.elements.played ||
					!e.detail.currentTimePercentage
				) {
					return;
				}

				const length = this.elements.range.offsetWidth || 0;

				this.elements.marker.style.transform = `translateX(${length * e.detail.currentTimePercentage}px)`;
				this.elements.played.style.transform = `scaleX(${e.detail.currentTimePercentage})`;

				if ( this.elements.audioProgress ) {
					const updatedClipPath = `polygon(0 0, ${e.detail.currentTimePercentage * 100}% 0, ${e.detail.currentTimePercentage * 100}% 100%, 0% 100%)`;

					this.elements.audioProgress.style.clipPath = updatedClipPath;
					this.elements.audioProgress.style.webkitClipPath = updatedClipPath;
				}
			}, window );
		}

		jump( mouseX ) {
			const length = this.elements.range.offsetWidth;
			const offset = this.elements.range.getBoundingClientRect().left;

			// get position relative to scrub width
			// e.g. if user clicks in exact mid of scrub
			// position will be 0.5
			const position = ( mouseX - offset ) / length;

			if ( position && !isNaN( position ) && 0 < position ) {
				this.el.dispatchEvent( new CustomEvent( 'mr-video-controls:jump', {
					bubbles: true,
					cancelable: true,
					detail: {
						position: position,
						videoId: this.videoId,
					},
				} ) );
			}
		}

		dispatch( event ) {
			this.el.dispatchEvent( new CustomEvent( `mr-video-controls:${event}`, {
				bubbles: true,
				cancelable: true,
				detail: {
					videoId: this.videoId,
				},
			} ) );
		}
	},
} );
