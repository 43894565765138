export class MrSlide extends HTMLElement {
	get type() {
		return false;
	}

	constructor( controller, index, data ) {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		this.controller = controller;
		this.index = index;
		this.data = data;

		this.exited = () => {
			console.warn( 'MrSlide.exited not implemented' );
		};

		this.entered = () => {
			console.warn( 'MrSlide.entered not implemented' );
		};

		this.exitListener = () => {
			this.addEventListener( 'transitionend', this.exitedAnimationHandler );
			this.animating = true;

			// fallback for not transitionend browsers
			this.fallbackTimeout = setTimeout( () => {
				if ( this.animating ) {
					this.exitedAnimationComplete();
				}
			}, 800 );
		};

		this.exitedAnimationHandler = ( e ) => {
			if ( e.target === this ) {
				this.exitedAnimationComplete();
			}
		};

		this.exitedAnimationComplete = () => {
			clearTimeout( this.fallbackTimeout );
			this.animating = false;
			this.removeEventListener( 'transitionend', this.exitedAnimationHandler );

			this.controller.removeChild( this );
			this.exited();
		};

		this.enterHandler = () => {
			this.animating = false;
			this.controller.classList.remove( 'is-animating' );
			this.controller.classList.remove( 'go-to-previous' );
			this.controller.classList.remove( 'go-to-next' );
			this.entered();
		};
	}

	connectedCallback() {}

	disconnectedCallback() {}

	enter() {
		this.controller.appendChild( this );
		this.enterHandler();
	}

	exitLeft() {
		this.controller.classList.add( 'go-to-next' );
		this.controller.classList.add( 'is-animating' );

		this.exitListener();
	}

	exitRight() {
		this.controller.classList.add( 'go-to-previous' );
		this.controller.classList.add( 'is-animating' );

		this.exitListener();
	}

	render() {
		this.removeRenderedVideos();

		this.innerHTML = '';
		this.className = 'spotlight-gallery__track js-spotlight-gallery-track';
		this.renderView();

		if ( this.type && 'news' !== this.type ) {
			const backgroundColor = this.data.settings.background_color || '#fff';
			const color = this.data.settings.color || '#000';
			this.updateColors( backgroundColor, color );
		} else {
			this.updateColors( '#fff', '#000' );
		}

		this.animating = false;
		this.controller.classList.remove( 'is-animating' );
	}

	renderView() {
		// Source: https://developer.mozilla.org/en-US/docs/Web/API/Document/createDocumentFragment
		const fragment = document.createDocumentFragment();

		if ( this.previousPreviousSlide ) {
			// PREVIOUS PREVIOUS
			const previousPreviousElement = this.renderElement( this.previousPreviousSlide.data, 'is-previous-previous' );
			fragment.appendChild( previousPreviousElement );
		} else {
			const previousPreviousElement = document.createElement( 'div' );
			previousPreviousElement.className = 'spotlight-gallery__item';
			fragment.appendChild( previousPreviousElement );
		}

		if ( this.previousSlide ) {
			// PREVIOUS
			const previousElement = this.renderElement( this.previousSlide.data, 'is-previous' );
			fragment.appendChild( previousElement );
		} else {
			const previousElement = document.createElement( 'div' );
			previousElement.className = 'spotlight-gallery__item';
			fragment.appendChild( previousElement );
		}

		const element = this.renderElement( this.data, 'is-current' );
		fragment.appendChild( element );

		if ( this.nextSlide ) {
			// NEXT
			const nextElement = this.renderElement( this.nextSlide.data, 'is-next' );
			fragment.appendChild( nextElement );
		} else {
			const nextElement = document.createElement( 'div' );
			nextElement.className = 'spotlight-gallery__item';
			fragment.appendChild( nextElement );
		}

		if ( this.nextNextSlide ) {
			// NEXT NEXT
			const nextNextElement = this.renderElement( this.nextNextSlide.data, 'is-next-next' );
			fragment.appendChild( nextNextElement );
		} else {
			const nextNextElement = document.createElement( 'div' );
			nextNextElement.className = 'spotlight-gallery__item';
			fragment.appendChild( nextNextElement );
		}

		this.appendChild( fragment );
	}

	renderElement( data, className ) {
		const element = document.createElement( 'div' );
		element.className = 'spotlight-gallery__item js-spotlight-gallery-item ' + className;

		const readmore = this.renderReadMore( data, className );
		if ( readmore ) {
			element.appendChild( readmore );
		}

		const wrapper = document.createElement( 'div' );
		wrapper.className = 'spotlight-gallery__item__wrapper';
		element.appendChild( wrapper );

		const inner = document.createElement( 'div' );
		inner.className = 'spotlight-gallery__item__inner js-spotlight-gallery-inner';
		wrapper.appendChild( inner );

		const container = document.createElement( 'div' );
		container.className = 'spotlight-gallery__item__container js-spotlight-gallery-container';
		inner.appendChild( container );

		const mediaContainer = document.createElement( 'div' );
		mediaContainer.className = 'spotlight-gallery__item__media-container';
		container.appendChild( mediaContainer );

		if ( data.linked_video && data.linked_video.has_linked_video && data.linked_video.video_html && !data.linked_video.youtube_id ) {
			const videoTeaser = this.renderVideoTeaser( data.imgix_src, data.dimensions, data.linked_video, data.id );
			mediaContainer.appendChild( videoTeaser );

			if ( !document.getElementById( 'asset-linked-video__container-' + data.id ) ) {
				const videoPlayerContainer = document.createElement( 'div' );
				videoPlayerContainer.id = 'asset-linked-video__container-' + data.id;
				videoPlayerContainer.className = 'js-asset-linked-video-container';
				videoPlayerContainer.innerHTML = data.linked_video.video_html;

				document.body.appendChild( videoPlayerContainer );
			}
		} else if ( data.linked_video && data.linked_video.has_linked_video && data.linked_video.youtube_id ) {
			const frame = this.renderYoutubeFrame( data.linked_video.youtube_id );

			mediaContainer.appendChild( frame );
			element.classList.add( 'is-loaded' );
		} else {
			const img = this.renderImage( data.imgix_src, data.dimensions );

			img.addEventListener( 'load', function() {
				const parent = this.closest( '.js-spotlight-gallery-item' );

				if ( !parent ) {
					return;
				}

				parent.classList.add( 'is-loaded' );
			} );

			mediaContainer.appendChild( img );
		}

		const contentContainer = document.createElement( 'div' );
		contentContainer.className = 'spotlight-gallery__item__content-container';
		contentContainer.appendChild( this.renderContent( data ) );
		container.appendChild( contentContainer );

		return element;
	}

	// eslint-disable-next-line no-unused-vars
	renderReadMore( data, className ) {}

	// eslint-disable-next-line no-unused-vars
	renderContent( data ) {}

	renderImage( src ) {
		const img = document.createElement( 'img' );
		img.setAttribute( 'loading', 'eager' );
		img.className = 'spotlight-gallery__item__media';

		// Get dpr with a max of 8
		const dpr = Math.min( ( window.devicePixelRatio ?? 1 ), 8 );

		// 100% on smallest
		const width = this.widthForViewPort();
		const height = ( Math.ceil( window.innerHeight / 100 ) * 100 ) - 240;

		let q = '80';
		if ( 1 < dpr ) {
			q = '60';
		}

		img.src = src + `?w=${width}&h=${height}&dpr=${dpr}&q=${q}&auto=format`;

		img.addEventListener( 'load', function() {
			const parent = this.closest( '.js-spotlight-gallery-item' );

			if ( !parent ) {
				return;
			}

			parent.classList.add( 'is-loaded' );
		} );

		return img;
	}

	renderVideoTeaser( src, dimensions, linkedVideo, id ) {
		if ( !linkedVideo || !id ) {
			if ( src && dimensions ) {
				const img = this.renderImage( src, dimensions );

				return img;
			}

			return;

		}

		const videoTeaser = document.createElement( 'mr-video-teaser' );
		const videoID = `asset-linked-video-${id}`;

		videoTeaser.className = 'video-teaser js-video-teaser';

		if ( linkedVideo.is_light && 0 !== linkedVideo.is_light ) {
			videoTeaser.className = `${videoTeaser.className} video-teaser--light`;
		}

		videoTeaser.setAttribute( 'video-id', videoID );

		const background = document.createElement( 'div' );
		background.className = 'video-teaser__background';

		videoTeaser.appendChild( background );

		const poster = document.createElement( 'img' );
		poster.className = 'video-teaser__poster';

		// Get dpr with a max of 8
		const dpr = Math.min( window.devicePixelRatio ?? 1, 8 );

		// 100% on smallest
		const width = this.widthForViewPort();
		const height = ( Math.ceil( window.innerHeight / 100 ) * 100 ) - 240;

		poster.src = src + `?w=${width}&h=${height}&dpr=${dpr}&auto=format&ch=Save-Data`;

		videoTeaser.appendChild( poster );

		poster.addEventListener( 'load', function() {
			const parent = this.closest( '.js-spotlight-gallery-item' );

			if ( !parent ) {
				return;
			}

			parent.classList.add( 'is-loaded' );
		} );

		const button = document.createElement( 'div' );
		button.className = 'video-teaser__button-container';
		button.innerHTML = '<svg class="video-teaser__button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path class="video-teaser__button__background" fill="currentColor" d="M32,64C14.4,64,0,49.6,0,32S14.4,0,32,0s32,14.4,32,32S49.6,64,32,64z M32,0.6C14.7,0.6,0.6,14.7,0.6,32S14.7,63.4,32,63.4 S63.4,49.3,63.4,32S49.3,0.6,32,0.6z"/><path class="video-teaser__button__play-icon" fill="currentColor" d="M24.9,47l-0.4-0.5c5.4-5.3,13.4-13.3,14.7-14.7L24.4,17.2l0.5-0.5l15,15v0.1C39.9,32,39.9,32.2,24.9,47z"/></svg>';

		videoTeaser.appendChild( button );

		return videoTeaser;
	}

	renderYoutubeFrame( youtubeID ) {
		const frame = document.createElement( 'iframe' );
		frame.className = 'spotlight-gallery__item__media spotlight-gallery__item__media--youtube';
		frame.setAttribute( 'frameborder', '0' );
		frame.setAttribute( 'allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' );
		frame.setAttribute( 'allowfullscreen', '' );
		frame.setAttribute( 'height', ( Math.ceil( window.innerHeight / 100 ) * 100 ) - 240 );
		frame.setAttribute( 'width', this.widthForViewPort() );
		frame.setAttribute(
			'style',
			`width: ${frame.getAttribute( 'width' )}px;` +
			`height: ${frame.getAttribute( 'height' )}px;`
		);

		frame.setAttribute( 'src', 'https://www.youtube.com/embed/' + youtubeID + '?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&color=white' );

		return frame;
	}

	updateColors( backgroundColor, color ) {
		if ( !backgroundColor || !color ) {
			return;
		}

		this.controller.style.backgroundColor = backgroundColor;
		this.controller.style.color = color;
	}

	convertToRGBA( hex, alpha ) {
		if ( !hex ) {
			return 'rgba(255,255,255,' + alpha + ')';
		}

		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );
		if ( result ) {
			return 'rgba(' + parseInt( result[1], 16 ) + ',' + parseInt( result[2], 16 ) + ',' + parseInt( result[3], 16 ) + ',' + alpha + ')';
		}

		return 'rgba(255,255,255,' + alpha + ')';
	}

	widthForViewPort() {
		let width = Math.ceil( window.innerWidth / 100 ) * 100;

		// Progressively smaller in relation to the viewport
		if ( window.matchMedia( '(min-width: 1920px)' ).matches ) {
			width = Math.ceil( window.innerWidth / 100 ) * 50;
		} else if ( window.matchMedia( '(min-width: 1680px)' ).matches ) {
			width = Math.ceil( window.innerWidth / 100 ) * 55;
		} else if ( window.matchMedia( '(min-width: 1280px)' ).matches ) {
			width = Math.ceil( window.innerWidth / 100 ) * 60;
		} else if ( window.matchMedia( '(min-width: 1024px)' ).matches ) {
			width = Math.ceil( window.innerWidth / 100 ) * 65;
		} else if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
			width = Math.ceil( window.innerWidth / 100 ) * 70;
		}

		return width;
	}

	removeRenderedVideos() {
		Array.from( document.querySelectorAll( '.js-asset-linked-video-container' ) ).forEach( ( video ) => {
			if ( video.parentNode ) {
				video.parentNode.removeChild( video );
			}
		} );
	}
}
