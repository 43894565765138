import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { checkSmallTouchDevice } from '@mrhenry/wp--check-small-touch-device';

defineCustomElement( 'mr-video-teaser', {
	attributes: [
		{
			attribute: 'video-id',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		bind() {
			this.on( 'click .js-video-teaser', ( e ) => {
				if ( !this.videoId ) {
					return;
				}

				e.preventDefault();
				e.stopPropagation();

				let smallTouchDevice = true;

				if ( checkSmallTouchDevice ) {
					smallTouchDevice = checkSmallTouchDevice();
				}

				this.el.dispatchEvent( new CustomEvent( 'mr-video-teaser:open', {
					bubbles: true,
					cancelable: true,
					detail: {
						// open in OS fullscreen player on (small) mobile devices
						fullscreen: smallTouchDevice,
						videoId: this.videoId,
					},
				} ) );
			} );
		}
	},
} );
