// https://instant.page
/*! instant.page v1.1.0 - (C) 2019 Alexandre Dieulot - https://instant.page/license */

// © 2019 Alexandre Dieulot
//
// Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.

let urlToPreload;
let mouseoverTimer;
let lastTouchTimestamp;

const prefetcher = document.createElement( 'link' );
const maybeCache = ( function() {
	try {
		return ( -1 === document.cookie.indexOf( 'wordpress_test_cookie=' ) );
	} catch ( err ) {
		return false;
	}
}() );

const isSupportedPrefetchRegardlessOfCache = prefetcher.relList && prefetcher.relList.supports && prefetcher.relList.supports( 'prefetch' );

const isSupportedPrefetch = maybeCache && isSupportedPrefetchRegardlessOfCache;
export const instantPageSupported = isSupportedPrefetchRegardlessOfCache;

( function() {
	if ( isSupportedPrefetch ) {
		prefetcher.rel = 'prefetch';
		document.head.appendChild( prefetcher );

		const eventListenersOptions = {
			capture: true,
			passive: true,
		};

		document.addEventListener( 'touchstart', touchstartListener, eventListenersOptions );
		document.addEventListener( 'mouseover', mouseoverListener, eventListenersOptions );

		return;
	}

}() );

function touchstartListener( event ) {
	if ( !event || !event.target || !event.target.closest ) {
		return;
	}

	/* Chrome on Android calls mouseover before touchcancel so `lastTouchTimestamp`
	* must be assigned on touchstart to be measured on mouseover. */
	lastTouchTimestamp = performance.now();

	const linkElement = event.target.closest( 'a' );

	if ( !linkElement ) {
		return;
	}

	if ( !isPreloadable( linkElement ) ) {
		return;
	}

	linkElement.addEventListener( 'touchcancel', touchendAndTouchcancelListener, {
		passive: true,
	} );
	linkElement.addEventListener( 'touchend', touchendAndTouchcancelListener, {
		passive: true,
	} );

	urlToPreload = linkElement.href;
	preload( linkElement.href );
}

function touchendAndTouchcancelListener() {
	urlToPreload = undefined;
	stopPreloading();
}

function mouseoverListener( event ) {
	if ( !event || !event.target || !event.target.closest ) {
		return;
	}

	if ( 1100 > performance.now() - lastTouchTimestamp ) {
		return;
	}

	const linkElement = event.target.closest( 'a' );

	if ( !linkElement ) {
		return;
	}

	if ( !isPreloadable( linkElement ) ) {
		return;
	}

	linkElement.addEventListener( 'mouseout', mouseoutListener, {
		passive: true,
	} );

	urlToPreload = linkElement.href;

	mouseoverTimer = setTimeout( () => {
		preload( linkElement.href );
		mouseoverTimer = undefined;
	}, 65 );
}

function mouseoutListener( event ) {
	if ( !event || !event.target || !event.target.closest ) {
		return;
	}

	if ( event.relatedTarget && event.target.closest( 'a' ) === event.relatedTarget.closest( 'a' ) ) {
		return;
	}

	if ( mouseoverTimer ) {
		clearTimeout( mouseoverTimer );
		mouseoverTimer = undefined;
	} else {
		urlToPreload = undefined;
		stopPreloading();
	}
}

function isPreloadable( linkElement ) {
	if ( urlToPreload === linkElement.href ) {
		return;
	}

	let urlObject;
	try {
		urlObject = new URL( linkElement.href );
	} catch ( err ) {
		console.warn( 'instantpage : failed to construct url -', err );

		return;
	}

	if ( !urlObject ) {
		return;
	}

	if ( urlObject.origin !== location.origin ) {
		return;
	}

	if ( urlObject.pathname.startsWith( '/wp-' ) ) {
		return;
	}

	if ( urlObject.hash && ( urlObject.pathname + urlObject.search ) === ( location.pathname + location.search ) ) {
		return;
	}

	if ( 'noInstant' in linkElement.dataset ) {
		return;
	}

	return true;
}

function preload( url ) {
	if ( isSupportedPrefetch ) {
		prefetcher.href = url;

		return;
	}
}

function stopPreloading() {
	/* The spec says an empty string should abort the prefetching
	* but Firefox 64 interprets it as a relative URL to prefetch. */
	prefetcher.removeAttribute( 'href' );
}
