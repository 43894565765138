/*
 * Mr Viewport Actions 🐲
 *
 * This component interacts with text color of featured gallery
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-viewport-actions', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		bind() {
			this.on( 'mr-featured-gallery:update', ( e ) => {
				if ( document.body.classList.contains( 'is-scrolled' ) ) {
					return;
				}

				if ( 'undefined' === typeof e.detail.current ) {
					return;
				}

				if ( !e.detail.items || !e.detail.items.length ) {
					return;
				}

				if ( !e.detail.visible ) {
					this.setColor( '#fff' );

					return;
				}

				const slide = e.detail.items[e.detail.current];
				if ( !slide ) {
					return;
				}

				if ( slide.classList.contains( 'is-light' ) ) {
					this.setColor( '#000' );
				} else {
					this.setColor( '#fff' );
				}
			}, document.body );
		}

		setColor( color ) {
			this.el.style.color = color;
		}
	},
} );
