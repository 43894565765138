import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrVideoOverlay extends MrModalDialog {

	#closeAfterHistoryBackHandler = () => {
		this.updateState( 'close' );
	};

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'has-open-video-overlay' );

		window.addEventListener( 'mr-pagereset', this.#closeAfterHistoryBackHandler, {
			once: true,
		} );

		const iframe = this.querySelector( 'iframe' );
		if ( !iframe || !iframe.dataset.src ) {
			return;
		}

		iframe.src = iframe.dataset.src;
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'has-open-video-overlay' );

		window.removeEventListener( 'mr-pagereset', this.#closeAfterHistoryBackHandler );

		// We want to stop the video embed playing when closing the overlay.
		const iframe = this.querySelector( 'iframe' );
		if ( !iframe ) return;
		iframe.src = '';
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '0',
					},
					{
						opacity: '1',
					},
				],
				{
					duration: 16,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '1',
					},
					{
						opacity: '0',
					},
				],
				{
					duration: 16,
					easing: 'linear',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-video-overlay', MrVideoOverlay );
