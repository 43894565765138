/*
 * Mr Site Header 🐲
 *
 * This component interacts with all content and updates it background and text color
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

import { isDark } from '../helpers/is-dark';

const SCROLL_TRIGGER = 50;
const SCROLL_MAX = 500;

defineCustomElement( 'mr-site-header', {
	controller: class extends BaseController {
		resolve() {
			const resolved = new Promise( ( resolve ) => {
				this.gatherElements( 10, () => {
					resolve();
				} );
			} );

			return resolved;
		}

		gatherElements( after = 10, done = () => {} ) {
			const siteHeader = document.getElementById( 'site-header' );
			const background = document.querySelector( '#site-header .js-site-header__background' );
			if ( siteHeader && background ) {
				this.siteHeader = siteHeader;
				this.background = background;
				done();

				return;
			}

			setTimeout( () => {
				this.gatherElements( after * 2, done );
			}, after );
		}

		init() {
			this.setIconColor( document.body.style.color );

			this.siteHeader.classList.add( 'is-resolved' );
		}

		bind() {
			// Setup body observer
			const config = {
				attributes: true,
				childList: false,
				subtree: false,
			};

			// Create an observer instance linked to the callback function
			const observer = new MutationObserver( ( mutationsList, o ) => {
				this.bodyMutationHandler( mutationsList, o );
			} );

			// Start observing the body
			observer.observe( document.body, config );

			// So now let's listen to the on scroll event
			this.on( 'mr-window-watcher:scroll', ( e ) => {
				// Regular scroll animation
				const pageYOffset = e.detail.position || 0;

				if ( pageYOffset > SCROLL_TRIGGER && !this.siteHeader.classList.contains( 'site-header--scrolled' ) ) {
					this.resetColor();
					this.siteHeader.classList.add( 'site-header--scrolled' );
				}

				if ( pageYOffset > SCROLL_MAX && !this.siteHeader.classList.contains( 'site-header--scrolled--max' ) ) {
					this.resetColor();
					this.siteHeader.classList.add( 'site-header--scrolled--max' );
				}

				if ( pageYOffset <= SCROLL_TRIGGER && this.siteHeader.classList.contains( 'site-header--scrolled' ) ) {
					this.siteHeader.classList.remove( 'site-header--scrolled' );
				}

				if ( pageYOffset <= SCROLL_MAX && this.siteHeader.classList.contains( 'site-header--scrolled--max' ) ) {
					this.siteHeader.classList.remove( 'site-header--scrolled--max' );
				}

				const siteFooter = document.querySelector( '.js-site-header__footer' );

				// Footer interaction
				if ( siteFooter ) {
					const footerTopPosition = siteFooter.getBoundingClientRect().top;
					const headerHeight = this.siteHeader.clientHeight;

					if ( footerTopPosition < headerHeight && !this.siteHeader.classList.contains( 'site-header--footer-is-visible' ) ) {
						this.siteHeader.classList.add( 'site-header--footer-is-visible' );
					}

					if ( footerTopPosition > headerHeight && this.siteHeader.classList.contains( 'site-header--footer-is-visible' ) ) {
						this.siteHeader.classList.remove( 'site-header--footer-is-visible' );
					}
				}
			}, document.body );

			setTimeout( () => {
				this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:current:scroll', {
					bubbles: true,
					cancelable: true,
				} ) );
			}, 0 );

			this.on( 'mr-featured-gallery:update', ( e ) => {
				if ( 'undefined' === typeof e.detail.current ) {
					return;
				}

				if ( !e.detail.items || !e.detail.items.length ) {
					return;
				}

				if ( !e.detail.visible || window.pageYOffset > SCROLL_TRIGGER ) {
					this.resetColor();
					this.featuredGalleryVisibleAndScrolledTop = false;

					return;
				}
				this.featuredGalleryVisibleAndScrolledTop = true;


				const slide = e.detail.items[e.detail.current];
				if ( !slide ) {
					return;
				}

				if ( slide.classList.contains( 'is-light' ) ) {
					this.featuredGalleryIsLight = true;
					this.setColor( '#000' );
				} else {
					this.featuredGalleryIsLight = false;
					this.setColor( '#fff' );
				}
			}, document.body );
		}

		bodyMutationHandler( mutationsList ) {
			let isStyleAnimation = false;
			for ( let i = 0; i < mutationsList.length; i++ ) {
				if ( 'attributes' === mutationsList[i].type && 'style' === mutationsList[i].attributeName ) {
					isStyleAnimation = true;
				}
			}

			if ( !isStyleAnimation ) {
				return;
			}


			this.setBackgroundColor( document.body.style.backgroundColor || '#fff' );

			if ( this.featuredGalleryVisibleAndScrolledTop && !document.body.classList.contains( 'is-navigating' ) ) {
				if ( this.featuredGalleryIsLight ) {
					this.setColor( '#000' );
				} else {
					this.setColor( '#fff' );
				}
			} else {
				this.setColor( document.body.style.color || '#000' );
			}
		}

		setColor( color ) {
			this.setIconColor( color );

			this.siteHeader.style.color = color;
		}

		resetColor() {
			this.setIconColor( document.body.style.color );

			this.siteHeader.removeAttribute( 'style' );
		}

		setBackgroundColor( backgroundColor ) {
			this.background.style.backgroundColor = backgroundColor;
		}

		setIconColor( color ) {
			if ( isDark( color ) ) {
				this.siteHeader.classList.remove( 'site-header--icon-white' );
				this.siteHeader.classList.add( 'site-header--icon-black' );
			} else {
				this.siteHeader.classList.add( 'site-header--icon-white' );
				this.siteHeader.classList.remove( 'site-header--icon-black' );
			}
		}
	},
} );
