class MrActivateWatchMode extends HTMLElement {
	#clickHandler = (): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		forEl.setAttribute( 'data-video-mode', 'watch' );

		if ( forEl.hasAttribute( 'show-credits' ) ) {
			setTimeout( () => {
				forEl.removeAttribute( 'show-credits' );

				const content: HTMLElement | null = forEl.querySelector( '.song-player-info__content' );

				if ( content && matchMedia( '(min-width: 768px) and (max-width: 1023px)' ).matches ) {
					content.style.transform = 'translateY(0)';
				}
			}, 160 );
		}
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}

	getForEl(): HTMLElement|null {
		const forElVideoId = this.getAttribute( 'for' );
		if ( !forElVideoId ) {
			return null;
		}

		return document.querySelector( `mr-video-player[video-id="${forElVideoId}"` );
	}
}

customElements.define( 'mr-activate-watch-mode', MrActivateWatchMode );
