import { renderAudioLevelsOnCanvas } from '../../audio-levels-bar/render-canvas';

declare global {
	interface Window {
		audioLevels: Record<string, Array<number>|undefined>;
	}
}

const renderedAudioLevels: WeakMap<HTMLCanvasElement, boolean> = new WeakMap();

function renderQueueAudioLevels( queue: Array<Element> | null ) {
	if	( !queue ) {
		return;
	}

	const canvas = queue.pop() as HTMLCanvasElement;
	if	( !canvas ) {
		return;
	}

	renderCanvasAudioLevels( canvas );

	if ( 0 < queue.length ) {
		requestAnimationFrame( () => {
			renderQueueAudioLevels( queue );
		} );
	}
}

function renderCanvasAudioLevels( canvas: HTMLCanvasElement | null ) {
	if	( !canvas ) {
		return;
	}

	const dataKey = canvas.getAttribute( 'audio-levels-bar' );
	if ( !dataKey ) {
		return;
	}

	const data = window.audioLevels[dataKey];
	if ( !data ) {
		return;
	}

	if ( renderedAudioLevels.has( canvas ) ) {
		return;
	}

	renderedAudioLevels.set( canvas, true );

	renderAudioLevelsOnCanvas( canvas, data );
}

( () => {
	function init() {
		if ( 'interactive' === document.readyState || 'complete' === document.readyState ) {
			const queue = Array.from( document.querySelectorAll( 'canvas[audio-levels-bar]' ) );
			if ( !queue || 0 === queue.length ) {
				return;
			}
			renderQueueAudioLevels( queue );
		}
	}
	requestAnimationFrame( init );
	document.addEventListener( 'readystatechange', init );
} )();
