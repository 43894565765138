/*
 * Mr Page Sections 📟
 *
 * Page sections emitting events when they enter the viewport
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-page-sections', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			if ( !( 'IntersectionObserver' in window ) ) {
				return;
			}

			this.clicked = false;
			this.current = 0;

			this.elements = {};
			this.elements.siteHeader = document.querySelector( '.js-site-header' );
			this.elements.sections = Array.from( this.el.querySelectorAll( '.js-page-section' ) );

			this.setOffsets();

			if (
				!this.elements.sections ||
				!this.elements.sections.length ||
				!this.offsets ||
				!this.offsets.bottom ||
				!this.offsets.top
			) {
				return;
			}

			const options = {
				rootMargin: `-${this.offsets.top}px 0px -${this.offsets.bottom}px 0px`,
			};

			const observer = new IntersectionObserver( ( entries ) => {
				for ( let i = 0; i < entries.length; i += 1 ) {
					const entry = entries[i];

					if ( entry.isIntersecting ) {
						const hitId = entry.target.getAttribute( 'id' ) || false;

						if ( !hitId ) {
							return;
						}

						if ( this.clicked === `#${hitId}` ) {
							this.clicked = false;

							return;
						}

						if ( this.clicked ) {
							return;
						}

						this.dispatchCurrentItem();
					}
				}
			}, options );

			for ( let i = 0; i < this.elements.sections.length; i += 1 ) {
				const section = this.elements.sections[i];
				observer.observe( section );
			}
		}

		bind() {
			this.on( 'mr-window-watcher:resize', () => {
				this.setOffsets();
			}, window );
		}

		setOffsets() {
			const windowHeight = window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight ||
				0;

			let minimum = 150;

			if ( this.elements.siteHeader ) {
				minimum = this.elements.siteHeader.offsetHeight + 50 || minimum;
			}

			let bottom = minimum + 50;
			if ( ( windowHeight * 0.6 ) > minimum ) {
				bottom = windowHeight * 0.6;
			}

			let top = minimum;
			if ( ( windowHeight * 0.2 ) > minimum ) {
				top = windowHeight * 0.2;
			}

			this.offsets = {
				bottom: bottom,
				top: top,
			};
		}

		dispatchCurrentItem() {
			if ( -1 === this.current ) {
				return;
			}

			this.el.dispatchEvent( new CustomEvent( 'mr-page-sections:update', {
				bubbles: true,
				cancelable: true,
				detail: {
					current: this.current,
				},
			} ) );
		}
	},
} );
