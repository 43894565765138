// Easing function
const easeInOutQuart = ( time, from, distance, duration ) => {
	let phase = time / ( duration / 2 );
	if ( 1 > phase ) {
		return distance / 2 * phase * phase * phase * phase + from;
	}

	phase = phase - 2;

	return -distance / 2 * ( phase * phase * phase * phase - 2 ) + from;
};

// shared state to prevent multiple scroll actions at the same time
let latestScrollAction = null;

/**
 * Smooth scroll animation
 * @param {int} endX: destination x coordinate
 * @param {int} endY: destination y coordinate
 * @param {int} duration: animation duration in ms
 * @param {int} done: callback when done
 * @param {int} cancelled: callback when cancelled
 */
export const smoothScrollTo = ( endX, endY, duration, done = () => {}, cancelled = () => {} ) => {
	const startX = window.scrollX || window.pageXOffset;
	const startY = window.scrollY || window.pageYOffset;
	const distanceX = endX - startX;
	const distanceY = endY - startY;
	const startTime = new Date().getTime();

	latestScrollAction = startTime;

	const dur = duration ?? 400;

	const animate = () => {
		// latestScrollAction is shared between all smoothScrollTo calls
		// startTime is local to a specific call
		// if they don't match we should stop scrolling
		if ( latestScrollAction !== startTime ) {
			cancelled();

			return;
		}

		const time = new Date().getTime() - startTime;
		const newX = easeInOutQuart( time, startX, distanceX, dur );
		const newY = easeInOutQuart( time, startY, distanceY, dur );
		window.scrollTo( newX, newY );
		// As long as the time didn't end, animate further
		if ( time <= dur ) {
			window.requestAnimationFrame( animate );
		} else {
			latestScrollAction = null; // reset
			done();
		}
	};

	window.requestAnimationFrame( animate );
};
