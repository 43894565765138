class MrSongTeaser extends HTMLElement {
	#clickHandler = ( e: MouseEvent ): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		// check if target is a button.
		if ( !e || !e.target || !( e.target instanceof HTMLButtonElement ) || !e.target.hasAttribute( 'data-video-mode' ) ) {
			return;
		}

		forEl.setAttribute( 'data-video-mode', e.target.getAttribute( 'data-video-mode' ) || 'listen' );

		this.dispatchEvent( new CustomEvent( 'mr-video-teaser:open', {
			bubbles: true,
			cancelable: true,
			detail: {
				fullscreen: false,
				videoId: this.getAttribute( 'for' ),
			},
		} ) );
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}

	getForEl(): HTMLElement|null {
		const forElVideoId = this.getAttribute( 'for' );
		if ( !forElVideoId ) {
			return null;
		}

		return document.querySelector( `mr-video-player[video-id="${forElVideoId}"` );
	}
}

customElements.define( 'mr-song-teaser', MrSongTeaser );
