import { MrDirectionTriggerBase } from './direction-trigger';

class MrTriggerRight extends MrDirectionTriggerBase {
	// updates the element indicated by 'for'
	trigger() {
		const forEl = this.forEl;
		if ( forEl ) {
			forEl.setAttribute( 'value', forEl.value + 1 );
		}
	}

	// which key trigger
	keyCode() {
		return 39;
	}

	// which swipe direction
	direction( dx ) {
		if ( 0 < dx ) {
			return false;
		}

		return true;
	}

	// threshold for swipe gestures
	exceedsIgnoreThreshold( dx, dy, dt ) {
		const settings = this.touchSettings();
		const allowedTime = settings.allowedTime;
		const threshold = settings.threshold;

		if ( ( dt > allowedTime ) || ( Math.abs( dx ) < threshold ) ) {
			return false;
		}

		return true;
	}
}

customElements.define( 'mr-trigger-right', MrTriggerRight );
