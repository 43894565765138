import { MrSlide } from './slide.js';

export class MrSpotlightNewsSlide extends MrSlide {
	get type() {
		return 'news';
	}

	renderContent( data ) {
		const content = document.createDocumentFragment();
		const intro = document.createElement( 'div' );
		intro.className = 'spotlight-gallery__item__intro js-spotlight-gallery-intro';
		content.appendChild( intro );

		if ( !data.post_date && !data.content ) {
			return intro;
		}

		if ( data.post_date ) {
			intro.innerHTML += data.post_date;
		}

		if ( data.content ) {
			intro.innerHTML += ' – ';
			intro.innerHTML += data.title;
			intro.innerHTML += ' – ';

			const readMoreLabel = document.createElement( 'label' );
			readMoreLabel.htmlFor = 'readmore-' + data.id;
			readMoreLabel.innerHTML = 'Read More +';
			intro.appendChild( readMoreLabel );

			const detail = document.createElement( 'div' );
			detail.className = 'spotlight-gallery__item__detail js-spotlight-gallery-detail';
			content.appendChild( detail );

			detail.innerHTML = data.content;
			detail.innerHTML += ' ';

			const readLessLabel = document.createElement( 'label' );
			readLessLabel.htmlFor = 'readmore-' + data.id;
			readLessLabel.innerHTML = 'Read Less -';
			detail.appendChild( readLessLabel );
		}

		return content;
	}

	renderReadMore( data, className ) {
		const fragment = document.createDocumentFragment();

		// if type is news, we need to add the readmore partials outside of the content container.
		if ( 'is-current' === className && data.post_date && data.content ) {
			const readMoreCheckbox = document.createElement( 'input' );
			readMoreCheckbox.type = 'checkbox';
			readMoreCheckbox.id = 'readmore-' + data.id;
			readMoreCheckbox.className = 'spotlight-gallery__item__checkbox js-spotlight-gallery-toggle';
			fragment.appendChild( readMoreCheckbox );

			// readmore toggle
			readMoreCheckbox.addEventListener( 'change', ( e ) => {
				if ( !e.target || !e.target.closest ) {
					return;
				}

				const parent = e.target.closest( '.js-spotlight-gallery-item' );

				if ( !parent ) {
					return;
				}

				const itemInner = parent.querySelector( '.js-spotlight-gallery-inner' );
				const itemIntro = parent.querySelector( '.js-spotlight-gallery-intro' );

				if ( itemInner && itemIntro ) {
					if ( e.target.checked ) {
						// checkbox is checked, animate up
						const itemDetail = parent.querySelector( '.js-spotlight-gallery-detail' );

						if ( itemDetail ) {
							const distance = Math.round( itemDetail.offsetHeight - itemIntro.offsetHeight );
							const transform = `translateY(-${distance}px)`;

							Object.assign( itemInner.style, {
								webkitTransform: transform,
								msTransform: transform,
								transform: transform,
							} );
						}
					} else if ( itemInner.hasAttribute( 'style' ) ) { // checkbox is unchecked, remove style
						itemInner.removeAttribute( 'style' );
					}
				}
			} );

			const gradient = document.createElement( 'div' );
			gradient.className = 'spotlight-gallery__item__gradient';
			gradient.setAttribute( 'style', 'background-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);' );
			fragment.appendChild( gradient );
		}

		return fragment;
	}
}

customElements.define( 'mr-spotlight-news-slide', MrSpotlightNewsSlide );
