( function() {
	const resetTogglesHandler = () => {
		const resettingToggles = document.querySelectorAll( '.js-toggle-reset-on-hide' );
		if ( resettingToggles && resettingToggles.length ) {
			for ( let i = 0; i < resettingToggles.length; i++ ) {
				resettingToggles[i].checked = false;
			}
		}
	};

	window.addEventListener( 'mr-pagereset', resetTogglesHandler, false );
}() );

( function() {
	const resetStylesHandler = () => {
		const resetStylesOn = document.querySelectorAll( '.js-style-reset-on-hide' );
		if ( resetStylesOn && resetStylesOn.length ) {
			for ( let i = 0; i < resetStylesOn.length; i++ ) {
				resetStylesOn[i].removeAttribute( 'style' );
			}
		}
	};

	window.addEventListener( 'mr-pagereset', resetStylesHandler, false );
}() );

( function() {
	const pageResetHandler = () => {

		// All listeners should expect to be called multiple times.
		// The goal is to be called at least once before showing a page navigated to by the browser back button.
		window.dispatchEvent(
			new CustomEvent(
				'mr-pagereset', {
					detail: {},
				}
			)
		);
	};

	// All possible events that indicate we should reset a page to the original state.
	window.addEventListener( 'pagehide', pageResetHandler, false );
	window.addEventListener( 'pageshow', pageResetHandler, false );

	window.addEventListener( 'load', () => {
		window.addEventListener( 'popstate', pageResetHandler, true );
	} );
}() );
