import { MrSlide } from './slide.js';

export class MrSpotlightArtistSlide extends MrSlide {
	get type() {
		return 'artist';
	}

	renderContent( data ) {
		const content = document.createDocumentFragment();
		const intro = document.createElement( 'div' );
		intro.className = 'spotlight-gallery__item__intro js-spotlight-gallery-intro type-v2-a';
		content.appendChild( intro );

		if ( !data.for && !data.title ) {
			return intro;
		}


		const readMoreTitle = document.createElement( 'span' );
		readMoreTitle.className = 'spotlight-gallery__item__title';
		intro.appendChild( readMoreTitle );

		if ( data.for ) {
			readMoreTitle.innerHTML += data.for;
		} else {
			readMoreTitle.innerHTML += data.title;
		}

		if ( data.caption ) {
			const readMoreLabel = document.createElement( 'label' );
			readMoreLabel.className = 'spotlight-gallery__item__label';
			readMoreLabel.htmlFor = 'readmore-' + data.id;
			readMoreLabel.innerHTML = 'Read More <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon fill="currentColor" points="41.2,54.8 64,32 41.2,9.2 38.1,12.2 55.7,29.7 0,29.7 0,33.9 56.1,33.9 38.1,51.8 "/></svg>';
			intro.appendChild( readMoreLabel );

			const detail = document.createElement( 'div' );
			detail.className = 'spotlight-gallery__item__detail js-spotlight-gallery-detail';
			content.appendChild( detail );

			if ( data.title ) {
				detail.innerHTML += data.title;
				detail.innerHTML += ' – ';
				detail.innerHTML += data.caption;
				detail.innerHTML += ' ';

				const readLessLabel = document.createElement( 'label' );
				readLessLabel.className = 'spotlight-gallery__item__label';
				readLessLabel.htmlFor = 'readmore-' + data.id;
				readLessLabel.innerHTML = 'Read Less <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><polygon fill="currentColor" points="41.2,54.8 64,32 41.2,9.2 38.1,12.2 55.7,29.7 0,29.7 0,33.9 56.1,33.9 38.1,51.8 "/></svg>';
				detail.appendChild( readLessLabel );
			}
		}

		return content;
	}

	renderReadMore( data, className ) {
		const fragment = document.createDocumentFragment();

		// if type is artist, we need to add the readmore partials outside of the content container.
		if ( 'is-current' === className && ( data.for || data.title ) && data.caption ) {
			const readMoreCheckbox = document.createElement( 'input' );
			readMoreCheckbox.type = 'checkbox';
			readMoreCheckbox.id = 'readmore-' + data.id;
			readMoreCheckbox.className = 'spotlight-gallery__item__checkbox js-spotlight-gallery-toggle';
			fragment.appendChild( readMoreCheckbox );

			// readmore toggle
			readMoreCheckbox.addEventListener( 'change', ( e ) => {
				if ( !e.target || !e.target.closest ) {
					return;
				}

				const parent = e.target.closest( '.js-spotlight-gallery-item' );

				if ( !parent ) {
					return;
				}

				const itemInner = parent.querySelector( '.js-spotlight-gallery-inner' );
				const itemIntro = parent.querySelector( '.js-spotlight-gallery-intro' );

				if ( itemInner && itemIntro ) {
					if ( e.target.checked ) {
						// checkbox is checked, animate up
						const itemDetail = parent.querySelector( '.js-spotlight-gallery-detail' );

						if ( itemDetail ) {
							const distance = Math.round( itemDetail.offsetHeight - itemIntro.offsetHeight );
							const transform = `translateY(-${distance}px)`;

							Object.assign( itemInner.style, {
								webkitTransform: transform,
								msTransform: transform,
								transform: transform,
							} );
						}
					} else if ( itemInner.hasAttribute( 'style' ) ) { // checkbox is unchecked, remove style
						itemInner.removeAttribute( 'style' );
					}
				}
			} );

			const gradient = document.createElement( 'div' );
			gradient.className = 'spotlight-gallery__item__gradient';
			gradient.setAttribute( 'style', `background-image: linear-gradient(to bottom, ${this.convertToRGBA( data.settings.background_color || null, 1 )} 0%, ${this.convertToRGBA( data.settings.background_color || null, 0 )} 100%);` );
			fragment.appendChild( gradient );
		}

		return fragment;
	}
}

customElements.define( 'mr-spotlight-artist-slide', MrSpotlightArtistSlide );
