let animated = false;

( function() {
	const eventListenersOptions = {
		capture: true,
		passive: false,
	};

	document.addEventListener( 'click', clickListener, eventListenersOptions );

	const resetAnimatingHandler = () => {
		animated = false;
	};

	window.addEventListener( 'mr-pagereset', resetAnimatingHandler, false );
	window.addEventListener( 'load', resetAnimatingHandler, false );
}() );

function clickListener( e ) {
	if ( animated ) {
		return;
	}

	if ( !e.target || !e.target.closest ) {
		return;
	}

	const linkElement = e.target.closest( 'a' );
	if ( !linkElement ) {
		return;
	}

	if ( !isTransitionable( linkElement ) ) {
		return;
	}

	const delay = parseInt( linkElement.getAttribute( 'data-transition-duration' ), 10 );
	animated = true;

	if ( isNaN( delay ) || 0 >= delay ) {
		return;
	}

	e.preventDefault();
	e.stopPropagation();

	// Fallback in case the animation/transition events don't work
	const delayPromise = new Promise( ( resolve ) => {
		setTimeout(
			() => {
				resolve();
			},
			delay + 50 // pad with 50ms, this should leave enough time for the animations to run
		);
	} );

	// Wait for animations to finish
	const animationDonePromise = new Promise( ( resolve ) => {
		let resolved = false;
		const resolver = () => {
			if ( resolved ) {
				return;
			}

			resolved = true;
			resolve();
		};

		window.dispatchEvent(
			new CustomEvent(
				'mr-page-transition',
				{
					detail: {
						navigate: resolver,
						el: linkElement, // pass the a tag, this should give animation implementers all the needed data
					},
				}
			)
		);
	} );

	Promise.race( [
		delayPromise,
		animationDonePromise,
	] ).then( () => {
		linkElement.click();
	} );
}

function isTransitionable( linkElement ) {
	let urlObject;

	try {
		urlObject = new URL( linkElement.href );
	} catch ( err ) {
		console.warn( 'delaypage : failed to construct url -', err );

		return;
	}

	if ( urlObject.origin !== location.origin ) {
		return;
	}

	if ( urlObject.pathname.startsWith( '/wp-' ) ) {
		return;
	}

	if ( urlObject.hash && ( urlObject.pathname + urlObject.search ) === ( location.pathname + location.search ) ) {
		return;
	}

	if ( 'noInstant' in linkElement.dataset ) {
		return;
	}

	return true;
}
