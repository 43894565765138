import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {

	#closeAfterHistoryBackHandler = () => {
		this.updateState( 'close' );
	};

	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );

		window.addEventListener( 'mr-pagereset', this.#closeAfterHistoryBackHandler, {
			once: true,
		} );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );

		window.removeEventListener( 'mr-pagereset', this.#closeAfterHistoryBackHandler );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '0',
						transform: 'translateX(100%)',
					},
					{
						opacity: '1',
						transform: 'translateX(0)',
					},
				],
				{
					delay: 80,
					duration: 640,
					easing: 'cubic-bezier(0.55,0.015,0.055,1)',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				this,
				[
					{
						opacity: '1',
						transform: 'translateX(0)',
					},
					{
						opacity: '0',
						transform: 'translateX(100%)',
					},
				],
				{
					delay: 64,
					duration: 640,
					easing: 'cubic-bezier(0.55,0.015,0.055,1)',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
