/*
 * Mr Window Watcher 🖥👀
 *
 * Sending out Custom Events to be (re-)used in JS
 * Setting body class variations to be used in CSS
 *
 * -----
 *
 * Elements we need:
 *
 * The <body>, because state modifier classes are added to it
 *
 * -----
 *
 * Events we listen for:
 *
 * A: Scroll Event
 * B: Resize Event
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-window-watcher', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		init() {
			this._scrollState = {
				position: window.pageYOffset,
				direction: null,
				isScrolled: 50 < window.pageYOffset,
			};

			this.scrollHandler( false );
		}

		lastScrollState() {
			return this._scrollState;
		}

		newScrollState() {
			const position = window.pageYOffset;

			if ( isNaN( position ) || !document.body ) {
				return;
			}

			let direction = 'down';

			if ( position < this._scrollState.position ) {
				direction = 'up';
			}

			let isScrolled = false;
			if ( 50 < position ) {
				isScrolled = true;
			}

			if ( isScrolled ) {
				document.body.classList.add( 'is-scrolled' );
			} else {
				document.body.classList.remove( 'is-scrolled' );
			}

			this._scrollState = {
				position: position,
				direction: direction,
				isScrolled: isScrolled,
			};

			return this._scrollState;
		}

		// [A]
		scrollHandler( update ) {
			let scrollState = null;
			if ( update ) {
				scrollState = this.newScrollState();
			} else {
				scrollState = this.lastScrollState();
			}

			this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:scroll', {
				bubbles: true,
				cancelable: true,
				detail: {
					direction: scrollState.direction,
					position: scrollState.position,
					scrolled: scrollState.isScrolled,
				},
			} ) );
		}

		// [B]
		resizeHandler() {
			this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:resize', {
				bubbles: true,
				cancelable: true,
			} ) );
		}

		bind() {
			// [A]
			let scrollThrottle = false;

			this.on( 'mr-window-watcher:current:scroll', () => {
				this.scrollHandler( false );
			}, document.body );

			this.on( 'scroll', () => {
				if ( !document.body ) {
					this.off( 'scroll', window );

					return;
				}

				if ( scrollThrottle ) {
					return;
				}

				scrollThrottle = true;
				this.scrollHandler( true );

				setTimeout( () => {
					scrollThrottle = false;
					this.scrollHandler( true );
				}, 96 );
			}, window, {
				passive: true,
			} );

			// [B]
			let resizeThrottle = false;

			this.on( 'resize', () => {
				if ( !document.body ) {
					this.off( 'resize', window );

					return;
				}

				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;
				this.resizeHandler();

				setTimeout( () => {
					resizeThrottle = false;
					this.resizeHandler();
				}, 240 );
			}, window, {
				passive: true,
			} );
		}
	},
} );
