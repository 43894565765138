import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';
import { bugsnagClient } from '@mrhenry/wp--bugsnag-config';

/*
 * Mr Featured Gallery 🖼
 *
 * Gallery with 'click to show next' interaction + indicator buttons
 *
 */
defineCustomElement( 'mr-featured-gallery', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback() {
			this.checkIfInScreen();
			this.start();
			this.render();
		}

		get current() {
			return parseFloat( this.el.getAttribute( 'current' ) );
		}

		set current( to ) {
			const parsed = parseFloat( to );

			if ( Number.isNaN( parsed ) ) {
				console.warn( `Could not set ${'current'} to ${to}` );
				this.el.removeAttribute( 'current' );
			} else {
				this.el.setAttribute( 'current', parsed );
			}

			this.currentChangedCallback( to );
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();
			this.startInterval();
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( !this.elements || !this.elements.length ) {
				return;
			}

			if ( this.current < this.elements.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = 0;
			}
		}

		previous() {
			if ( !this.elements || !this.elements.length ) {
				return;
			}

			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = this.elements.length - 1;
			}
		}

		resolve() {
			const resolved = new Promise( ( resolve ) => {
				this.gatherElements( 30, () => {
					resolve();
				} );
			} );

			return resolved;
		}

		gatherElements( after = 30, done = () => {} ) {
			this.elements = {};

			const buttons = Array.from( this.el.querySelectorAll( '.js-featured-gallery-button' ) );
			const items = Array.from( this.el.querySelectorAll( '.js-featured-gallery-item' ) );
			if ( buttons && buttons.length && items && items.length ) {
				this.elements = {};
				this.elements.buttons = buttons;
				this.elements.items = items;
				this.interuptVideoPlaybackUntilStarted();
				done();

				return;
			}

			setTimeout( () => {
				this.gatherElements( after * 2, done );
			}, after );
		}

		init() {
			this.start();
			this.hasStartedOnce = true;

			this.resumeVideoPlaybackAfterStarted();

			return;
		}

		bind() {
			this.on( 'click .js-featured-gallery-button', ( e, target ) => {
				e.preventDefault();

				const index = parseFloat( target.getAttribute( 'index' ) );

				if ( null === index || isNaN( index ) ) {
					return;
				}

				this.current = index;

				if ( this.looper ) {
					clearInterval( this.looper );
					this.startInterval();
				}
			} );

			this.on( 'mr-window-watcher:scroll', ( e ) => {
				if ( 'undefined' !== typeof e.detail.position ) {
					this.checkIfInScreen( e.detail.position );
				}
			}, document.body );

			setTimeout( () => {
				this.el.dispatchEvent( new CustomEvent( 'mr-window-watcher:current:scroll', {
					bubbles: true,
					cancelable: true,
				} ) );
			}, 0 );
		}

		render() {
			this.elements.items.forEach( ( item, i ) => {
				if ( item.classList.contains( 'is-active' ) ) {
					item.classList.remove( 'is-active' );
				}
				if ( item.classList.contains( 'is-next' ) ) {
					item.classList.remove( 'is-next' );
				}
				if ( item.classList.contains( 'is-previous' ) ) {
					item.classList.remove( 'is-previous' );
				}

				if ( i === this.current ) {
					item.classList.add( 'is-active' );
				}

				( Array.from( item.querySelectorAll( 'video' ) ) ).forEach( ( video ) => {
					this.setPlayPauseForSingleVideo( video, i );
				} );
			} );

			this.elements.buttons.forEach( ( button, i ) => {
				if ( button.classList.contains( 'is-active' ) ) {
					button.classList.remove( 'is-active' );
				}

				if ( i === this.current ) {
					button.classList.add( 'is-active' );
				}
			} );

			// set next and previous
			let nextId = 0;
			if ( this.current < this.elements.length - 1 ) {
				nextId = this.current + 1;
			}

			const nextItem = this.elements.items[nextId];
			if ( nextItem ) {
				this.elements.items[nextId].classList.add( 'is-next' );
			}

			let previousId = this.elements.length - 1;
			if ( 0 < this.current ) {
				previousId = this.current - 1;
			}

			const previousItem = this.elements.items[previousId];
			if ( previousItem ) {
				this.elements.items[previousId].classList.add( 'is-previous' );
			}
		}

		checkIfInScreen( currentScrollTop ) {
			const rect = this.el.getBoundingClientRect();
			const scrollTop = currentScrollTop ?? window.pageYOffset;
			const viewportHeight = document.documentElement.clientHeight;
			const rectTopInScreen = ( ( rect.top + scrollTop ) > ( scrollTop - ( viewportHeight / 2 ) ) );
			const rectBottomInScreen = ( ( rect.bottom + scrollTop ) < ( scrollTop + viewportHeight + ( viewportHeight / 2 ) ) );

			if ( rectTopInScreen && rectBottomInScreen ) {
				this.visible = true;
			} else {
				this.visible = false;
			}

			this.emit( 'mr-featured-gallery:update', {
				current: this.current,
				items: this.elements.items,
				visible: this.visible,
			} );
		}

		startInterval() {
			if ( !this.auto || 0 === this.auto || isNaN( this.auto ) ) {
				return;
			}

			this.looper = setInterval( () => {
				this.next();
			}, this.auto );
		}

		destroy() {
			this.stop();
			super.destroy();
		}

		interuptVideoPlaybackUntilStarted() {
			if ( !this.elements.items || !this.elements.items.length ) {
				return;
			}

			this.elements.items.forEach( ( item ) => {
				( Array.from( item.querySelectorAll( 'video' ) ).forEach( ( video ) => {
					video.addEventListener( 'play', () => {
						if ( !this.hasStartedOnce ) {
							video.pause();

							return;
						}
					} );
				} ) );
			} );
		}

		resumeVideoPlaybackAfterStarted() {
			if ( !this.elements.items || !this.elements.items.length ) {
				return;
			}

			this.elements.items.forEach( ( item, index ) => {
				( Array.from( item.querySelectorAll( 'video' ) ).forEach( ( video ) => {
					this.setPlayPauseForSingleVideo( video, index );
				} ) );
			} );
		}

		setPlayPauseForSingleVideo( video, index ) {
			if ( !video ) {
				return;
			}

			if ( index !== this.current ) {
				video.pause();

				return;
			}

			if ( !this.hasStartedOnce ) {
				video.pause();

				return;
			}

			if ( !video.paused ) {
				return;
			}

			const playPromise = video.play();
			if ( !playPromise ) {
				return;
			}

			playPromise.then( () => {
				// all is well
			} ).catch( ( err ) => {
				console.log( 'featured-gallery : start playback for video in viewport - failed' );

				if ( video.src ) {
					console.log( 'featured-gallery : src - ' + video.src );
				}

				if ( video.error ) {
					console.log( 'featured-gallery : err - ' + video.error.code + '; details :', video.error.message );
				} else {
					// Devices with battery or data saving mode enabled might not allow playing this video
					console.log( 'featured-gallery : unable / not allowed to play video' );
				}

				if ( isUnexpectedError( err ) ) {
					bugsnagClient.notify( err );
				}
			} );
		}
	},
} );

const isUnexpectedError = ( err ) => {
	if ( err && 'AbortError' === err.name ) {
		return false;
	}

	if ( err && 'PlayInterrupted' === err.name ) {
		return false;
	}

	if ( err && 'NotAllowedError' === err.name ) {
		return false;
	}

	if ( err && 'NotSupportedError' === err.name ) {
		return false;
	}

	return true;
};
