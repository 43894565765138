export class MrDirectionTriggerBase extends HTMLElement {
	static get observedAttributes() {
		return [
			'disabled',
			'for',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		// Event Handlers
		this.click = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			e.preventDefault();
			this.trigger();
		};

		this.keydown = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			if ( e.keyCode !== this.keyCode() ) {
				return;
			}

			this.trigger();
		};

		this.touchstart = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			this._touchSettings.startX = e.changedTouches[0].screenX;
			this._touchSettings.startY = e.changedTouches[0].screenY;
			this._touchSettings.startTime = new Date().getTime();
		};

		this.touchend = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			const distX = e.changedTouches[0].screenX - this._touchSettings.startX;
			const distY = e.changedTouches[0].screenY - this._touchSettings.startY;
			const elapsedTime = ( new Date().getTime() ) - this._touchSettings.startTime;

			const exceedsIgnoreThreshold = this.exceedsIgnoreThreshold( distX, distY, elapsedTime );

			if ( !exceedsIgnoreThreshold ) {
				return;
			}

			if ( !this.direction( distX, distY ) ) {
				return;
			}

			this.trigger();
		};
		// Event Handlers
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'disabled' === attrName ) {
			if ( null === newVal ) {
				this._addEventListeners( this.forEl );
			} else {
				this._removeEventListeners( this.forEl );
			}

			return;
		}

		if ( 'for' === attrName ) {
			if ( oldVal ) {
				const forEl = document.getElementById( oldVal );
				this._removeEventListeners( forEl );
			} else {
				this._removeEventListeners( null );
			}

			if ( newVal ) {
				const forEl = document.getElementById( newVal );
				this._addEventListeners( forEl );
			} else {
				this._addEventListeners( null );
			}

			return;
		}
	}

	_addEventListeners( forEl ) {
		this._resetTouchSettings();

		this.addEventListener( 'click', this.click );

		window.addEventListener( 'keydown', this.keydown, {
			passive: true,
		} );

		if ( forEl ) {
			document.addEventListener( 'touchstart', this.touchstart );
			document.addEventListener( 'touchend', this.touchend );
		}
	}

	_removeEventListeners( forEl ) {
		this.removeEventListener( 'click', this.click );

		window.removeEventListener( 'keydown', this.keydown, {
			passive: true,
		} );

		if ( forEl ) {
			forEl.removeEventListener( 'touchstart', this.touchstart );
			forEl.removeEventListener( 'touchend', this.touchend );
		}

		this._resetTouchSettings();
	}

	_resetTouchSettings() {
		this._touchSettings = {
			startX: 0,
			startY: 0,
			threshold: 100,
			allowedTime: 400,
			startTime: 0,
		};
	}

	touchSettings() {
		return this._touchSettings;
	}

	// Sub class methods

	// updates the element indicated by 'for'
	trigger() {}

	// which key trigger
	keyCode() {}

	// which swipe direction
	// eslint-disable-next-line no-unused-vars
	direction( dx, dy ) {}

	// threshold for swipe gestures
	// eslint-disable-next-line no-unused-vars
	exceedsIgnoreThreshold( dx, dy, dt ) {}
}
