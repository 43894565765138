import './instantpage';
import './delaypage';
import './pagereset';
import { smoothScrollTo } from '../../helpers/smooth-scroll';


( function() {
	const setInitialStateForTransitions = () => {
		const originalBackgroundColor = document.body.style.backgroundColor;
		const originalColor = document.body.style.color;

		const resetBodyHandler = () => {
			document.body.style.backgroundColor = originalBackgroundColor;
			document.body.style.color = originalColor;

			document.body.classList.remove( 'is-animating-creative-list' );
			document.body.classList.remove( 'is-fading-out-main' );
			document.body.classList.remove( 'is-navigating' );
		};

		window.addEventListener( 'mr-pagereset', resetBodyHandler, false );

		window.addEventListener( 'mr-page-transition', ( e ) => {
			if ( !e.detail || !e.detail.el || !e.detail.navigate ) {
				return;
			}
			const detail = e.detail;

			// get the transition type
			const type = e.detail.el.getAttribute( 'data-transition-type' );

			if ( type && 'creative-list' === type ) {
				smoothScrollTo( 0, 0, 256 );

				// Scroll page to top
				setTimeout( () => {
					creativeTransition( detail );
				}, 384 );
			} else {
				defaultTransition( detail );
			}

			// Toggle off site header footer is visible class if available
			const siteHeader = document.getElementById( 'site-header' );
			if ( siteHeader && siteHeader.classList.contains( 'site-header--footer-is-visible' ) ) {
				siteHeader.classList.remove( 'site-header--footer-is-visible' );
			}
		} );
	};

	let checkReady = () => {
		if ( document.body ) {
			checkReady = () => {};
			setInitialStateForTransitions();

			// Return so that we don't call requestAnimationFrame() again
			return;
		}

		// If the body element isn't found, run checkReady() again at the next paint
		window.requestAnimationFrame( checkReady );
	};

	// Initialize our checkReady() function
	window.requestAnimationFrame( checkReady );
}() );

function setColors( detail ) {
	if ( document.body ) {
		// Set colors
		const backgroundColor = detail.el.getAttribute( 'data-transition-background-color' );
		if ( backgroundColor ) {
			document.body.style.backgroundColor = `${backgroundColor}`;
		}

		const color = detail.el.getAttribute( 'data-transition-color' );
		if ( color ) {
			document.body.style.color = `${color}`;
		}
	}
}

function defaultTransition( detail ) {
	setColors( detail );

	const pageAnimate = ( e ) => {
		if ( 'js-main' === !e.target.id ) {
			return;
		}

		detail.navigate();
	};

	// Function to add Event Listeners
	const addAnimationListeners = ( el ) => {
		el.addEventListener( 'webkitAnimationEnd', pageAnimate );
		el.addEventListener( 'animationend', pageAnimate );

		el.addEventListener( 'webkitTransitionEnd', pageAnimate );
		el.addEventListener( 'transitionend', pageAnimate );
	};

	// select main element
	const main = document.getElementById( 'js-main' );

	// if main element doesnt exist navigate to next page anyway
	if ( !main ) {
		detail.navigate();

		return;
	}

	// Default animation
	document.body.classList.add( 'is-navigating' );
	document.body.classList.add( 'is-fading-out-main' );

	addAnimationListeners( main );
}

function creativeTransition( detail ) {
	setColors( detail );

	const container = document.querySelector( '.js-creative-list-container' );
	const itemRect = detail.el.getBoundingClientRect();

	const pageAnimate = ( e ) => {
		if ( !e.target.classList.contains( 'js-creative-list-media' ) ) {
			return;
		}

		if ( 'transform' !== e.propertyName && '-webkit-transform' !== e.propertyName ) {
			return;
		}

		detail.navigate();
	};

	// Function to add Event Listeners
	const addAnimationListeners = ( el ) => {
		el.addEventListener( 'webkitAnimationEnd', pageAnimate );
		el.addEventListener( 'animationend', pageAnimate );

		el.addEventListener( 'webkitTransitionEnd', pageAnimate );
		el.addEventListener( 'transitionend', pageAnimate );
	};

	addAnimationListeners( detail.el );

	// Creative List animation
	document.body.classList.add( 'is-navigating' );
	document.body.classList.add( 'is-animating-creative-list' );

	// Start animation
	detail.el.classList.add( 'is-current' );

	const FADE_DELAY = 128;

	// reference site-header-spacer
	const SPACINGS = {
		default: ( 4.6875 + 1.625 ) * 16,
		small: ( 7.5 + 6.0625 ) * 16,
		medium: ( 5.625 + 5.3125 ) * 16,
		wide: ( 5.625 + 6.8125 ) * 16,
		xlarge: ( 6.3125 + 5.4375 ) * 16,
	};

	let spacing = SPACINGS.default;

	if ( window.matchMedia( '(min-width: 1920px)' ).matches ) {
		spacing = SPACINGS.xlarge;
	} else if ( window.matchMedia( '(min-width: 1280px)' ).matches ) {
		spacing = SPACINGS.wide;
	} else if ( window.matchMedia( '(min-width: 1024px)' ).matches ) {
		spacing = SPACINGS.medium;
	} else if ( window.matchMedia( '(min-width: 768px)' ).matches ) {
		spacing = SPACINGS.small;
	}

	if ( container && itemRect ) {
		const transform = `translateY(${Math.round( spacing - ( itemRect.top + window.scrollY ) )}px)`;

		// Wait for fade, scroll current to top
		setTimeout( () => {
			Object.assign( container.style, {
				webkitTransform: transform,
				msTransform: transform,
				transform: transform,
			} );
		}, FADE_DELAY );
	}
}
