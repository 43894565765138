/**
 * Check for small touch devices
 */

export const checkSmallTouchDevice = ( thresholdWidth = 1024 ) => {
	const checkTouch = () => {
		const prefixes = ' -webkit- -moz- -o- -ms- '.split( ' ' );
		const mq = ( query ) => {
			return window.matchMedia( query ).matches;
		};

		if ( 'ontouchstart' in window ) {
			return true;
		}

		if ( window.DocumentTouch && document instanceof window.DocumentTouch ) {
			return true;
		}

		// include the 'heartz' as a way to have a non matching MQ to help terminate the join
		// https://git.io/vznFH
		const query = [
			'(',
			prefixes.join( 'touch-enabled),(' ),
			'heartz',
			')',
		].join( '' );

		return mq( query );
	};

	const checkSmall = () => {
		const width = window.innerWidth;

		if ( width && width < thresholdWidth ) {
			return true;
		}

		return false;
	};

	if ( checkTouch() && checkSmall() ) {
		return true;
	}

	return false;
};
