const renderedBlurredImage: WeakMap<HTMLCanvasElement, boolean> = new WeakMap();

function renderCanvasBlurredImage( canvas: HTMLCanvasElement | null ) {
	if	( !canvas ) {
		return;
	}

	const imgSrc = canvas.getAttribute( 'img-src' );
	if ( !imgSrc ) {
		return;
	}

	if ( renderedBlurredImage.has( canvas ) ) {
		return;
	}

	renderedBlurredImage.set( canvas, true );


	const image = new Image();
	image.onload = () => {
		if	( !canvas ) {
			return;
		}

		const ctx = canvas.getContext( '2d' );
		if	( !ctx ) {
			return;
		}

		canvas.width = image.naturalWidth * window.devicePixelRatio;
		canvas.height = image.naturalHeight * window.devicePixelRatio;
		ctx.drawImage( image, 0, 0, canvas.width, canvas.height );

		const strength = 2;

		ctx.globalAlpha = 0.5; // Higher alpha made it more smooth
		// Add blur layers by strength to x and y
		// 2 made it a bit faster without noticeable quality loss
		for ( let y = -strength; y <= strength; y += 2 ) {
			for ( let x = -strength; x <= strength; x += 2 ) {
				// Apply layers
				ctx.drawImage( canvas, x, y );
				// Add an extra layer, prevents it from rendering lines
				// on top of the images (does makes it slower though)
				if ( 0 <= x && 0 <= y ) {
					ctx.drawImage( canvas, -( x - 1 ), -( y - 1 ) );
				}
			}
		}
		ctx.globalAlpha = 1.0;
	};

	image.src = imgSrc;
}

( () => {
	function init() {
		const queue = Array.from( document.querySelectorAll( 'canvas[blurred-image]' ) );
		if	( !queue || 0 === queue.length ) {
			return;
		}

		queue.forEach( ( canvas: Element ) => {
			if ( !( canvas instanceof HTMLCanvasElement ) ) {
				return;
			}

			renderCanvasBlurredImage( canvas );
		} );
	}

	init();
	requestAnimationFrame( init );
	document.addEventListener( 'readystatechange', init );
} )();
