class MrSongPagination extends HTMLElement {
	#clickHandler = ( e: MouseEvent ): void => {
		if ( !this.hasAttribute( 'for' ) ) {
			return;
		}

		// check if target is a button.
		if ( !e || !e.target || !( e.target instanceof HTMLButtonElement ) || !e.target.hasAttribute( 'video-id' ) ) {
			return;
		}

		this.dispatchEvent( new CustomEvent( 'mr-song-pagination:close', {
			bubbles: true,
			cancelable: true,
			detail: {
				videoId: this.getAttribute( 'for' ),
			},
		} ) );

		this.dispatchEvent( new CustomEvent( 'mr-video-teaser:open', {
			bubbles: true,
			cancelable: true,
			detail: {
				fullscreen: false,
				videoId: e.target.getAttribute( 'video-id' ),
			},
		} ) );
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}
}

customElements.define( 'mr-song-pagination', MrSongPagination );
