import { MrSlidesController } from './slides-controller.js';
import { MrSpotlightArtistSlide } from './spotlight-artist-slide.js';
import { MrSpotlightAssetSlide } from './spotlight-asset-slide.js';
import { MrSpotlightNewsSlide } from './spotlight-news-slide.js';
import { checkSmallTouchDevice } from '@mrhenry/wp--check-small-touch-device';

class MrSpotlightController extends MrSlidesController {
	// Attributes
	get type() {
		return this.getAttribute( 'type' );
	}

	prepareSlides() {
		if ( !this.data || !this.data.length ) {
			return;
		}

		if ( !this.type || ( 'artist' === !this.type && 'asset' === !this.type && 'news' === !this.type ) ) {
			return;
		}

		// check device
		this.isMobile = checkSmallTouchDevice();
		this.slides = [];

		let previousPreviousSlide = null;
		let previousSlide = null;
		for ( let i = 0; i < this.data.length; i++ ) {
			const slideData = this.data[i];
			let slide;

			if ( 'artist' === this.type ) {
				slide = new MrSpotlightArtistSlide( this, i, slideData );
			}
			if ( 'asset' === this.type ) {
				slide = new MrSpotlightAssetSlide( this, i, slideData );
			}
			if ( 'news' === this.type ) {
				slide = new MrSpotlightNewsSlide( this, i, slideData );
			}

			if ( previousSlide ) {
				previousSlide.nextSlide = slide;
				slide.previousSlide = previousSlide;

				if ( previousPreviousSlide ) {
					previousPreviousSlide.nextNextSlide = slide;
					slide.previousPreviousSlide = previousPreviousSlide;
				}

				previousPreviousSlide = previousSlide;
			}

			if ( i === ( this.data.length - 1 ) ) {
				this.slides[0].previousSlide = slide;
				this.slides[0].previousPreviousSlide = previousSlide;
				this.slides[1 % this.data.length].previousPreviousSlide = slide;
				slide.nextSlide = this.slides[0];
				slide.nextNextSlide = this.slides[1 % this.data.length];
				this.slides[Math.max( this.data.length - 2, 0 )].nextNextSlide = this.slides[0];
			}

			previousSlide = slide;

			this.slides[i] = slide;
		}
	}

	slideAtIndex( index ) {
		if ( !this.slides || !this.slides.length || index >= this.slides.length ) {
			return;
		}

		return this.slides[index];
	}

	goTo( indexer, oldIndex, newIndex ) {
		if ( null === newIndex ) {
			this.hide();

			return;
		}

		if ( null === oldIndex ) {
			this.show();
		}

		indexer.disabled = true;

		const slide = this.slideAtIndex( newIndex );
		if ( !slide ) {
			indexer.disabled = false;

			return;
		}

		slide.render();

		slide.entered = () => {
			indexer.disabled = false;
		};

		const enter = () => {
			this.currentSlide = slide;
			this.currentSlide.enter();
		};

		if ( !this.currentSlide ) {
			enter();

			return;
		}

		const exit = () => {
			if ( ( oldIndex === ( newIndex - 1 ) || oldIndex > ( newIndex + 1 ) ) ) {
				this.currentSlide.exitLeft();

				return;
			}

			this.currentSlide.exitRight();
		};

		this.currentSlide.exited = () => {
			enter();
		};

		exit();
	}

	hide() {
		this.classList.remove( 'is-open' );
		document.body.classList.remove( 'has-open-spotlight-overlay' );

		this.removeRenderedVideos();

		for ( let i = 0; i < this.slides.length; i++ ) {
			const slide = this.slides[i];

			if ( slide.parentNode ) {
				slide.parentNode.removeChild( slide );
			}
		}

		this.updateControls( false );
		this.currentSlide = false;
		this.prepareSlides();
	}

	show() {
		this.updateControls( true );
		this.classList.add( 'is-open' );
		document.body.classList.add( 'has-open-spotlight-overlay' );
	}

	updateControls( enabled ) {
		for ( let i = 0; i < this.children.length; i++ ) {
			if ( enabled ) {
				this.children[i].removeAttribute( 'disabled' );
			} else {
				this.children[i].setAttribute( 'disabled', true );
			}
		}
	}

	removeRenderedVideos() {
		Array.from( document.querySelectorAll( '.js-asset-linked-video-container' ) ).forEach( ( video ) => {
			if ( video.parentNode ) {
				video.parentNode.removeChild( video );
			}
		} );
	}
}

customElements.define( 'mr-spotlight-controller', MrSpotlightController );
