class MrShowBackground extends HTMLElement {
	#mouseenterHandler = (): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		if ( forEl.classList.contains( 'is-active' ) ) {
			return;
		}

		requestAnimationFrame( () => {
			forEl.classList.add( 'is-active' );
		} );
	};

	#mouseleaveHandler = (): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		if ( !forEl.classList.contains( 'is-active' ) ) {
			return;
		}

		requestAnimationFrame( () => {
			forEl.classList.remove( 'is-active' );
		} );
	};

	connectedCallback() {
		this.addEventListener( 'mouseenter', this.#mouseenterHandler );
		this.addEventListener( 'mouseleave', this.#mouseleaveHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'mouseenter', this.#mouseenterHandler );
		this.removeEventListener( 'mouseleave', this.#mouseleaveHandler );
	}

	getForEl(): HTMLElement|null {
		const forElId = this.getAttribute( 'for' );
		if ( !forElId ) {
			return null;
		}

		return document.getElementById( forElId );
	}
}

customElements.define( 'mr-show-background', MrShowBackground );
