/*
 * Mr Toggle 🌓
 *
 * Toggles active state between sibling buttons
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-toggle', {
	attributes: [],
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		bind() {
			this.on( 'click .js-toggle-item', ( e, target ) => {
				const activeSibling = this.el.querySelector( '.js-toggle-item.is-active' );

				if ( activeSibling ) {
					this.removeActive( activeSibling );
				}

				this.setActive( target );
			}, this.el );
		}

		removeActive( el ) {
			if ( !el ) {
				return;
			}

			el.classList.remove( 'is-active' );

			const forElID = el.getAttribute( 'for' );
			if ( !forElID ) {
				return;
			}

			const forEl = document.getElementById( forElID );
			if ( !forEl ) {
				return;
			}

			forEl.classList.remove( 'is-active' );
		}

		setActive( el ) {
			if ( !el ) {
				return;
			}

			el.classList.add( 'is-active' );

			const forElID = el.getAttribute( 'for' );
			if ( !forElID ) {
				return;
			}

			const forEl = document.getElementById( forElID );
			if ( !forEl ) {
				return;
			}

			forEl.classList.add( 'is-active' );
		}
	},
} );
