class MrTriggerUnset extends HTMLElement {
	static get observedAttributes() {
		return [
			'disabled',
			'for',
		];
	}

	constructor() {
		// If you define a constructor, always call super() first!
		// This is specific to CE and required by the spec.
		super();

		// Event Handlers
		this.click = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			e.preventDefault();
			this.trigger();
		};

		this.keydown = ( e ) => {
			if ( this.disabled ) {
				return;
			}

			if ( 27 !== e.keyCode ) {
				return;
			}

			this.trigger();
		};
		// Event Handlers
	}

	// Life cycle
	connectedCallback() {
		this._addEventListeners();
	}

	disconnectedCallback() {
		this._removeEventListeners();
	}

	// Attributes
	get disabled() {
		return this.hasAttribute( 'disabled' );
	}

	set disabled( val ) {
		if ( val ) {
			this.setAttribute( 'disabled', '' );
		} else {
			this.removeAttribute( 'disabled' );
		}
	}

	get forEl() {
		const forID = this.getAttribute( 'for' );
		if ( !forID ) {
			return null;
		}

		return document.getElementById( forID );
	}

	attributeChangedCallback( attrName, oldVal, newVal ) {
		if ( 'disabled' === attrName ) {
			if ( null === newVal ) {
				this._addEventListeners();
			} else {
				this._removeEventListeners();
			}

			return;
		}
	}

	_addEventListeners() {
		this.addEventListener( 'click', this.click );

		window.addEventListener( 'keydown', this.keydown, {
			passive: true,
		} );
	}

	_removeEventListeners() {
		this.removeEventListener( 'click', this.click );

		window.removeEventListener( 'keydown', this.keydown, {
			passive: true,
		} );
	}

	trigger() {
		const forEl = this.forEl;
		if ( forEl ) {
			forEl.setAttribute( 'value', '' );
		}
	}
}

customElements.define( 'mr-trigger-unset', MrTriggerUnset );
