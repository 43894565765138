class MrActivateListenMode extends HTMLElement {
	#clickHandler = (): void => {
		const forEl = this.getForEl();
		if ( !forEl ) {
			return;
		}

		forEl.setAttribute( 'data-video-mode', 'listen' );
	};

	connectedCallback() {
		this.addEventListener( 'click', this.#clickHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );
	}

	getForEl(): HTMLElement|null {
		const forElVideoId = this.getAttribute( 'for' );
		if ( !forElVideoId ) {
			return null;
		}

		return document.querySelector( `mr-video-player[video-id="${forElVideoId}"` );
	}
}

customElements.define( 'mr-activate-listen-mode', MrActivateListenMode );
