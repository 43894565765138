const is_touch_device = () => {
	try {
		document.createEvent( 'TouchEvent' );

		return true;
	} catch ( e ) {
		return false;
	}
};

let init = () => {
	if ( is_touch_device() ) {
		init = () => {}; // noop

		return;
	}

	if ( 'complete' !== document.readyState ) {
		return;
	}

	init = () => {}; // noop

	const stacked = document.getElementsByClassName( 'js-load-stacked-images' );
	// check if stacked doesnt exists
	if ( 1 > stacked.length ) {
		return;
	}

	for ( let i = 0; i < stacked.length; i++ ) {
		stacked[i].classList.add( 'ready-for-stack-images' );
	}
};

document.addEventListener( 'readystatechange', () => {
	init();
}, false );

init();
