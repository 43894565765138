import { MrSlide } from './slide.js';

export class MrSpotlightAssetSlide extends MrSlide {
	get type() {
		return 'asset';
	}

	renderContent( data ) {
		const content = document.createElement( 'p' );

		if ( !data.parent && !data.content ) {
			return content;
		}

		if ( data.parent ) {
			const parent = document.createElement( 'a' );
			parent.href = data.parent.link;
			parent.innerHTML = data.parent.title;
			content.appendChild( parent );

			if ( data.for ) {
				content.innerHTML += ' for ';
			}
		}

		if ( data.for ) {
			content.innerHTML += data.for;
		}

		return content;
	}
}

customElements.define( 'mr-spotlight-asset-slide', MrSpotlightAssetSlide );
